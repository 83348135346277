export const optionsForDoctor = [
    { id: 1, name: "Patient demography",key:"crtl+b" },
    { id: 2, name: "Chief compliant",key:"crtl+shift+c"  },
    { id: 3, name: "Vital signs" ,key:"crtl+shift+v"},
    { id: 13, name: "Physical exam",key:"crtl+shift+e" },
    { id: 15, name: "Diagnosis",key:"crtl+d" },
    { id: 4, name: "Prescription",key:"crtl+r" },
    { id: 16, name: "Care plan",key:"crtl+q" },
    { id: 5, name: "Vision",key:"" },
    { id: 6, name: "Lab Reports / Documents",key:"" },
    { id: 14, name: "Ot Notes",key:"" },
    { id: 7, name: "Visit history",key:"" },
    { id: 8, name: "Past medical history",key:"" },
    { id: 9, name: "Family histories",key:"" },
    { id: 10, name: "Surgical histories",key:"" },
    { id: 11, name: "Allergy history",key:"" },
    { id: 12, name: "Social histories",key:"" },
];

export const optionsForStaff = [
    { id: 1, name: "Patient demography",key:"" },
    { id: 2, name: "Chief compliant",key:"" },
    { id: 3, name: "Vital signs",key:"" },
    { id: 15, name: "Diagnosis",key:"" },
    // { id: 4, name: "Prescription" },
    { id: 5, name: "Vision",key:"" },
    { id: 6, name: "Lab Reports / Documents",key:"" },
    { id: 7, name: "Visit History",key:"" },
    { id: 8, name: "Past medical history",key:"" },
    { id: 9, name: "Family Histories" ,key:""},
    { id: 10, name: "Surgical Histories",key:"" },
    { id: 11, name: "Allergy history",key:"" },
    { id: 12, name: "Social Histories" ,key:""},
];

export const optionsForPatientDetails = [
    { id: 1, name: "Patient demography" },
    // { id: 2, name: "Chief compliant" },
    { id: 3, name: "Vital signs" },
    { id: 12, name: "Physical exam" },
    { id: 11, name: "Vision" },
    { id: 4, name: "Lab Reports / Documents" },
    { id: 14, name: "Ot Notes" },
    { id: 5, name: "Visit History" },
    { id: 6, name: "Past medical history" },
    { id: 7, name: "Family Histories" },
    { id: 8, name: "Surgical Histories" },
    { id: 9, name: "Allergy history" },
    { id: 10, name: "Social Histories" },
];



export const routesData = [
    { id: 1, value_en: "Apply to Affected Area", value_bn: "প্রভাবিত স্থানে প্রয়োগ করুন" },
    { id: 2, value_en: "Apply to Skin", value_bn: "ত্বকে প্রয়োগ করুন" },
    { id: 20, value_en: "Apply inside the skin", value_bn: "ত্বকের ভিতরে প্রয়োগ করুন" },
    { id: 3, value_en: "Both Ears", value_bn: "উভয় কানে" },
    { id: 21, value_en: "Under the skin", value_bn: "চামড়ার নিচে" },
    { id: 4, value_en: "Both Eyes", value_bn: "উভয় চোখে" },
    { id: 5, value_en: "Both Nostrils", value_bn: "উভয় নাসারন্ধ্রে" },
    { id: 6, value_en: "By Mouth", value_bn: "মুখে খাবেন" },
    { id: 20, value_en: "Take by sucking", value_bn: "চুষে খাবেন" },
    { id: 7, value_en: "In the Vagina/Vaginally", value_bn: "যোনিতে" },
    { id: 8, value_en: "Intramuscular", value_bn: "পেশীতে ইনজেকশন" },
    { id: 9, value_en: "Intravenous", value_bn: "শিরায় ইনজেকশন" },
    { id: 10, value_en: "Left Ear", value_bn: "বাম কানে" },
    { id: 11, value_en: "Left Eye", value_bn: "বাম চোখে" },
    { id: 12, value_en: "Left Nostril", value_bn: "বাম নাসারন্ধ্রে" },
    { id: 13, value_en: "Rectally", value_bn: "মলদ্বারে" },
    { id: 14, value_en: "Right Ear", value_bn: "ডান কানে" },
    { id: 15, value_en: "Right Eye", value_bn: "ডান চোখে" },
    { id: 16, value_en: "Right Nostril", value_bn: "ডান নাসারন্ধ্রে" },
    { id: 17, value_en: "Transdermal", value_bn: "ত্বকের মাধ্যমে" },
    { id: 18, value_en: "Via Catheter", value_bn: "ক্যাথেটারের মাধ্যমে" },
    { id: 19, value_en: "Via PEG", value_bn: "পিইজি টিউবের মাধ্যমে" },
]

export const frequencyData=[
    { id: 1, value_en: "1+1+1", value_bn: "১+১+১" },
    { id: 2, value_en: "1+0+1", value_bn: "১+০+১" },
    { id: 3, value_en: "1+1+0", value_bn: "১+১+০" },
    { id: 4, value_en: "1+0+0", value_bn: "১+০+০" },
    { id: 5, value_en: "0+1+1", value_bn: "০+১+১" },
    { id: 6, value_en: "0+1+0", value_bn: "০+১+০" },
    { id: 7, value_en: "0+0+1", value_bn: "০+০+১" },
    { id: 15, value_en: "2+1+0", value_bn: "২+১+০" },
    { id: 16, value_en: "2+2+2", value_bn: "২+২+২" },
    { id: 16, value_en: "2+0+0", value_bn: "২+0+0" },
    { id: 8, value_en: "1/2+1/2+1/2", value_bn: "১/২+১/২+১/২" },
    { id: 9, value_en: "1/2+0+1/2", value_bn: "১/২+০+১/২" },
    { id: 10, value_en: "1/2+1/2+0", value_bn: "১/২+১/২+০" },
    { id: 11, value_en: "1/2+0+0", value_bn: "১/২+০+০" },
    { id: 12, value_en: "0+1/2+1/2", value_bn: "০+১/২+১/২" },
    { id: 14, value_en: "1+1+1+1", value_bn: "১+১+১+১" },
    { id: 15, value_en: "1+1+1+1+1", value_bn: "১+১+১+১+১" },
]

export const repeatData=[
    { id: 1, value_en: "day(s)", value_bn: "দিন" },
    { id: 2, value_en: "week(s)", value_bn: "সপ্তাহ" },
]

export const durationTileData=[
    { id: 1, value_en: "Day(s)", value_bn: "দিন" },
    { id: 2, value_en: "Week(s)", value_bn: "সপ্তাহ" },
    { id: 3, value_en: "Month(s)", value_bn: "মাস" },
    { id: 5, value_en: "Continue", value_bn: "চলবে" },
    { id: 6, value_en: "If needed", value_bn: "প্রয়োজনে" }
]

export const instructions=[
    { id: 1, value_en: "before meal", value_bn: "খাবার আগে" },
    { id: 2, value_en: "after meal", value_bn: "খাবার পরে" },
    { id: 3, value_en: "30 minutes before meal", value_bn: "খাবারের ৩০ মিনিট আগে" },
    { id: 4, value_en: "30 minutes after meal", value_bn: "খাবারের ৩০ মিনিট পরে" },
    { id: 5, value_en: "full stomach", value_bn: "ভরা পেটে" },
]




