import React, { useEffect, useState, useMemo } from 'react'
import { patientHistory } from '../../../service/api/patient-api';
import "./patient-history.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import VisionHistory from '../vision-components/vision-history';
import { convertDobToAge, dateFormat } from '../../../utils/common-funtions/common';


const PatientHistory = ({ patientId, referredBy }) => {
    const [patientHistoryInfo, setPatientHistoryInfo] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [patient, setPatient] = useState({})
    const [visitDetails, setVisitDetails] = useState({})

    useEffect(() => {

        if (patientId) {
            refresh(patientId)
        }

    }, [patientId])


    const refresh = async (id = patientId) => {
        setIsLoading(true)
        patientHistory(id)
            .then((res) => {
                if (res?.status === 200) {
                    setPatientHistoryInfo(res?.data)
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
            })
            .finally(() => {
                setIsLoading(false)
            }
            );
    }

    const contentHideFun = (id) => {
        const icon = document?.getElementById(`${id}-icon`)
        const table = document?.getElementById(`${id}-table`)
        if (icon.classList?.contains("rotate-180")) {
            icon.classList.remove("rotate-180");
        } else {
            icon.classList?.add("rotate-180");
        }
        if (table.classList?.contains("d-none")) {
            table.classList.remove("d-none");
        } else {
            table.classList?.add("d-none");
        }
    }

    useMemo(() => {
        if (patientHistoryInfo?.patient) {
            setPatient(patientHistoryInfo?.patient)
        }
        if (patientHistoryInfo?.visitDetails) {
            if (patientHistoryInfo?.visitDetails[0]) {
                setVisitDetails(patientHistoryInfo?.visitDetails[0])
            }
        }
    }, [patientHistoryInfo?.patient, patientHistoryInfo?.visitDetails])



    return (
        <div className='full-history-section' style={{ height: "94vh" }}>
            <div onClick={() => {
                refresh()
            }} className='p-0 m-0'><small className='p-0 m-0 px-1 mx-0' style={{ backgroundColor: "#0EAC81", color: 'white', cursor: 'pointer' }}>Refresh {isLoading ? <span
                style={{ fontSize: "12px", width: "10px", height: "10px" }}
                className="spinner-border spinner-border-sm "
                role="status"
                aria-hidden="true"
            ></span> : <FontAwesomeIcon size='sm' className='mx-1' icon={faArrowsRotate} />} </small></div>
            <div className='surgical-histories-section'>
                <div className='d-flex header justify-content-between pl-1 pr-3 border-top' onClick={() => {
                    contentHideFun("patient-details")
                }}>
                    <label>Patient Details</label>
                    <FontAwesomeIcon id={`patient-details-icon`} size="xs" className="mt-1 " icon={faChevronUp} />
                </div>
                <div>
                    <table id={`patient-details-table`} className="table table-sm table-hover">
                        <div className='d-flex flex-wrap justify-content-between patientInfo pb-1 pt-1 mx-1'
                            style={{ fontSize: "13px" }}>
                            {referredBy?.length > 1 && <p className='mr-2'>Referred By: <span>{referredBy}</span></p>}
                            <p className='mr-2'>Name: <span> {patientHistoryInfo?.patient?.name}</span></p>
                            <p className='mr-2'>Blood Group: <span> {patientHistoryInfo?.patient?.bloodGroup || " "}</span></p>
                            <p className='mr-2'>Age: <span> {convertDobToAge(patientHistoryInfo?.patient?.dob?.slice(0, 10), "year(s)") || " "}</span></p>
                            <p className='mr-2'>Gender: <span> {patientHistoryInfo?.patient?.gender || " "}</span></p>
                        </div>
                    </table>
                </div>
            </div>
            <div className='surgical-histories-section'>
                <div className='d-flex header justify-content-between pl-1 pr-3 border-top' onClick={() => {
                    contentHideFun("surgical-histories")
                }}>
                    <label>Surgical History</label>
                    <FontAwesomeIcon id={`surgical-histories-icon`} size="xs" className="mt-1 " icon={faChevronUp} />
                </div>
                <div>
                    <table id={`surgical-histories-table`} className="table table-sm table-hover">
                        <thead>
                            {patient.surgicalHistories && patient.surgicalHistories?.length ? <tr>
                                <th scope="col">Name</th>
                                {/* <th scope="col">Note</th> */}
                            </tr> : <small className='mx-1'>No previous surgical history</small>}
                        </thead>
                        <tbody>
                            {
                                patient?.surgicalHistories && patient?.surgicalHistories?.map((problem, index) => {
                                    return (
                                        <>
                                            {
                                                <tr key={index} className={``}>
                                                    <td>{problem?.name}</td>
                                                </tr>
                                            }
                                        </>
                                    );
                                })
                            }
                            {/*{patient?.surgicalHistories && patient?.surgicalHistories?.others?.length > 0 && <tr className='m-0 p-0'>*/}
                            {/*    <td colSpan={6}> Others: {patient?.surgicalHistories?.others}</td>*/}
                            {/*</tr>}*/}

                        </tbody>
                    </table>
                </div>
            </div>
            <div className='family-history-section'>
                <div className='d-flex header justify-content-between pl-1 pr-3 border-top' onClick={() => {
                    contentHideFun("family-histories")
                }}>
                    <label>Family History</label>
                    <FontAwesomeIcon id={`family-histories-icon`} size="xs" className="mt-1 " icon={faChevronUp} />
                </div>
                <div>
                    <table id={`family-histories-table`} className="table table-sm table-hover">
                        <thead>
                            {patient?.familyHistories && patient?.familyHistories?.length ? <tr>
                                <th scope="col">Disease</th>
                                <th scope="col">From</th>
                                {/* <th scope="col">Note</th> */}
                            </tr> : <small className='mx-1'>No previous family history</small>}
                        </thead>
                        <tbody>
                            {
                                patient?.familyHistories && patient?.familyHistories?.map((problem, index) => {
                                    return (
                                        <>
                                            {
                                                <tr key={index} className={``}>
                                                    <td>{problem?.name}</td>
                                                    <td>{problem?.from}</td>
                                                </tr>
                                            }
                                        </>
                                    );
                                })
                            }
                            {/*{patient?.familyHistories && patient?.familyHistories?.others?.length > 0 && <tr className='m-0 p-0'>*/}
                            {/*    <td colSpan={6}> Others: {patient?.familyHistories?.others}</td>*/}
                            {/*</tr>}*/}

                        </tbody>
                    </table>
                </div>
            </div>
            <div className='social-histories-section'>
                <div className='d-flex header justify-content-between pl-1 pr-3 border-top' onClick={() => {
                    contentHideFun("social-history")
                }}>
                    <label>Social History</label>
                    <FontAwesomeIcon id={`social-history-icon`} size="xs" className="mt-1 " icon={faChevronUp} />
                </div>
                <div>
                    <table id={`social-history-table`} className="table table-sm table-hover">
                        <thead>
                            {patient?.socialHistories && patient?.socialHistories?.length ? <tr>

                                <th scope="col">Habits</th>
                                <th scope="col">Note</th>
                                {/* <th scope="col">Note</th> */}
                            </tr> : <small className='mx-1'>No previous social history</small>}
                        </thead>
                        <tbody>
                            {
                                patient?.socialHistories?.map((problem, index) => {
                                    return (
                                        <>
                                            {
                                                <tr key={index} className={``}>
                                                    <td>{problem?.name}</td>
                                                    <td>{problem?.note}</td>
                                                </tr>
                                            }
                                        </>
                                    );
                                })
                            }
                            {/*{patient?.socialHistories?.others?.length > 0 && <tr className='m-0 p-0'>*/}
                            {/*    <td colSpan={6}> Others: {patient?.socialHistories?.others}</td>*/}
                            {/*</tr>}*/}

                        </tbody>
                    </table>
                </div>
            </div>

            <div className='past-medical-histories-section'>
                <div className='d-flex header justify-content-between pl-1 pr-3 border-top' onClick={() => {
                    contentHideFun("past-medical")
                }}>
                    <label>Past Medical Histories</label>
                    <FontAwesomeIcon id={`past-medical-icon`} size="xs" className="mt-1 " icon={faChevronUp} />
                </div>
                <div>
                    <table id={`past-medical-table`} className="table table-sm table-hover">
                        <thead>
                            {patient?.pastMedicalHistories?.length ? <tr>

                                <th scope="col">Name</th>
                                <th scope="col">Note</th>
                                {/* <th scope="col">Note</th> */}
                            </tr> : <small className='mx-1'>No past medical history</small>}
                        </thead>
                        <tbody>
                            {
                                patient?.pastMedicalHistories?.map((problem, index) => {
                                    return (
                                        <>
                                            {
                                                <tr key={index} className={``}>
                                                    <td>{problem?.name}</td>
                                                    <td>{problem?.note}</td>
                                                </tr>
                                            }
                                        </>
                                    );
                                })
                            }
                            {/*{patient?.pastMedicalHistories?.others?.length > 0 && <tr className='m-0 p-0'>*/}
                            {/*    <td colSpan={6}> Others: {patient?.pastMedicalHistories?.others}</td>*/}
                            {/*</tr>}*/}

                        </tbody>
                    </table>
                </div>
            </div>

            <div className='allergy-histories-section'>
                <div className='d-flex header justify-content-between pl-1 pr-3 border-top' onClick={() => {
                    contentHideFun("allergy-histories")
                }}>
                    <label>Allergy Histories</label>
                    <FontAwesomeIcon id={`allergy-histories-icon`} size="xs" className="mt-1 " icon={faChevronUp} />
                </div>
                <div>
                    <table id={`allergy-histories-table`} className="table table-sm table-hover">
                        <thead>
                            {patient?.allergyHistories?.length ? <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Type</th>
                                <th scope="col">Reaction</th>
                                <th scope="col">Severity</th>
                                <th scope="col">Source</th>
                                {/* <th scope="col">Note</th> */}
                            </tr> : <small className='mx-1'>No previous allergy history</small>}
                        </thead>
                        <tbody>
                            {
                                patient?.allergyHistories?.map((problem, index) => {
                                    return (
                                        <>
                                            {
                                                <tr key={index} className={``}>
                                                    <td>{problem?.item}</td>
                                                    <td>{problem?.type}</td>
                                                    <td>{problem?.reaction}</td>
                                                    <td>{problem?.severity}</td>
                                                    <td>{problem?.source}</td>
                                                </tr>
                                            }
                                        </>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className='complaints-section'>
                <div className='d-flex header justify-content-between pl-1 pr-3 border-top' onClick={() => {
                    contentHideFun("complaints")
                }}>
                    <label>Complaints</label>
                    <FontAwesomeIcon id={`complaints-icon`} size="xs" className="mt-1 " icon={faChevronUp} />
                </div>
                <div>
                    <table id={`complaints-table`} className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Reason For Visit</th>
                                <th scope="col">History Of Preset Illness</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                patientHistoryInfo[0]?.compliants?.map((complaint, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{complaint?.createdAt?.slice(0, 10)}</td>
                                            <td>{complaint?.reasonForVisit}</td>
                                            <td>{complaint?.historyOfPresetIllness}</td>
                                        </tr>
                                    );
                                })
                            }
                            {isLoading && <tr><td className="mx-2">loading...</td></tr>}
                        </tbody>
                    </table>
                </div>
            </div> */}
            <div className='vital-sign-section'>

                <div className='d-flex header justify-content-between pl-1 pr-3 border-top' onClick={() => {
                    contentHideFun("vital")
                }}>
                    <label>Vital Signs</label>
                    <FontAwesomeIcon id={`vital-icon`} size="xs" className="mt-1 " icon={faChevronUp} />
                </div>
                <div>
                    <table id={`vital-table`} className="table table-sm table-hover">
                        <thead>
                            {visitDetails?.vitals?.length ? <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Ht(ft/inch)</th>
                                <th scope="col">Wt(kg)</th>
                                <th scope="col">Temp(F)</th>
                                <th scope="col">BMI</th>
                                <th scope="col">BP(mmHg)</th>
                                <th scope="col">Hr(/min)</th>
                                <th scope="col">SPO2(%)</th>
                                {/* <th scope="col">Note</th> */}
                            </tr> : <small className='mx-1'>No previous vital signs</small>}
                        </thead>
                        <tbody>

                            {visitDetails?.vitals?.map((vital, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{dateFormat(vital?.date?.slice(0, 10))}</td>
                                        <td>{vital?.height}</td>
                                        <td>{vital?.weight}</td>
                                        <td>{vital?.temperature}</td>
                                        <td>{vital?.bmi}</td>
                                        <td>{vital?.bp}</td>
                                        <td>{vital?.hr}</td>
                                        <td>{vital?.spo2}</td>

                                    </tr>
                                );
                            })
                            }
                            {isLoading && <tr>
                                <td className="mx-2">loading...</td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='medicines-section'>
                <div className='d-flex header justify-content-between pl-1 pr-3 border-top' onClick={() => {
                    contentHideFun("medicines")
                }}>
                    <label>Recent Medication</label>
                    <FontAwesomeIcon id={`medicines-icon`} size="xs" className="mt-1 " icon={faChevronUp} />
                </div>
                <div>
                    <table id={`medicines-table`} className="table table-sm table-hover">
                        <thead>
                            {visitDetails?.medicines?.length ? <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Sig</th>
                                <th scope="col">Duration</th>
                                {/* <th scope="col">Note</th> */}
                            </tr> : <small className='mx-1'>No recent medication history</small>}
                        </thead>
                        <tbody>
                            {
                                visitDetails?.medicines?.map((medicine, index) => {
                                    return (
                                        <>
                                            {
                                                <tr key={index} className={``}>

                                                    <td>{medicine?.name}</td>
                                                    <td>{medicine?.sig}</td>
                                                    <td>{medicine?.duration}</td>
                                                </tr>
                                            }
                                        </>
                                    );
                                })
                            }
                            {/* {patientHistoryInfo[0]?.familyHistories[0]?.others?.length>0 &&<tr className='m-0 p-0'>
                                <td colSpan={6}> Others: {patientHistoryInfo[0]?.familyHistories[0]?.others}</td>
                            </tr>} */}

                        </tbody>
                    </table>
                </div>
            </div>
            {/*<div className='lab-test-section'>*/}
            {/*    <div className='d-flex header justify-content-between pl-1 pr-3 border-top' onClick={() => {*/}
            {/*        contentHideFun("lab-test")*/}
            {/*    }}>*/}
            {/*        <label>Recent Investigation</label>*/}
            {/*        <FontAwesomeIcon id={`lab-test-icon`} size="xs" className="mt-1 " icon={faChevronUp} />*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <table id={`lab-test-table`} className="table table-sm table-hover">*/}
            {/*            <thead>*/}
            {/*                {patientHistoryInfo[0]?.prescriptions && patientHistoryInfo[0]?.prescriptions?.labTests?.length ? <tr>*/}

            {/*                    <th scope="col">Name</th>*/}
            {/*                    /!* <th scope="col">Note</th> *!/*/}
            {/*                </tr> : <small className='mx-1'>No recent investigation history</small>}*/}
            {/*            </thead>*/}
            {/*            <tbody>*/}
            {/*                {*/}
            {/*                    patientHistoryInfo[0]?.prescriptions && patientHistoryInfo[0]?.prescriptions?.labTests?.map((labTest, index) => {*/}
            {/*                        return (*/}
            {/*                            <>*/}
            {/*                                {*/}
            {/*                                    <tr key={index} className={``}>*/}

            {/*                                        <td>{labTest?.name}</td>*/}
            {/*                                    </tr>*/}
            {/*                                }*/}
            {/*                            </>*/}
            {/*                        );*/}
            {/*                    })*/}
            {/*                }*/}
            {/*                /!* {patientHistoryInfo[0]?.familyHistories[0]?.others?.length>0 &&<tr className='m-0 p-0'>*/}
            {/*                    <td colSpan={6}> Others: {patientHistoryInfo[0]?.familyHistories[0]?.others}</td>*/}
            {/*                </tr>} *!/*/}

            {/*            </tbody>*/}
            {/*        </table>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className='advice-section'>
                <div className='d-flex header justify-content-between pl-1 pr-3 border-top' onClick={() => {
                    contentHideFun("vision")
                }}>
                    <label>Vision</label>
                    <FontAwesomeIcon id={`vision-icon`} size="xs" className="mt-1 " icon={faChevronUp} />
                </div>
                <div id={`vision-table`}>
                    {visitDetails?.eyeExamination?.length > 0 ? <div>
                        {visitDetails?.eyeExamination?.map((vision, index) => {
                            return <div key={index} className='mb-2'>
                                {index != 0 && <hr></hr>}
                                <VisionHistory maxWidth={"100%"} info={vision}></VisionHistory>
                            </div>
                        })}
                    </div> : <small className='mx-1'>No recent vision history</small>}
                </div>
            </div>

            {/* <div className='advice-section'>
                <div className='d-flex header justify-content-between pl-1 pr-3 border-top' onClick={() => {
                    contentHideFun("advice")
                }}>
                    <label>Most recent advices</label>
                    <FontAwesomeIcon id={`advice-icon`} size="xs" className="mt-1 " icon={faChevronUp} />
                </div>
                <div>
                    <table id={`advice-table`} className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Advice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                patientHistoryInfo[0]?.prescriptions[0]?.advices?.map((advice, index) => {
                                    return (
                                        <>
                                            {
                                                <tr key={index} className={``}>
                                                    <td>{index + 1}</td>
                                                    <td>{advice?.details}</td>
                                                </tr>
                                            }
                                        </>
                                    );
                                })
                            }
                            {patientHistoryInfo[0]?.familyHistories[0]?.others?.length>0 &&<tr className='m-0 p-0'>
                                <td colSpan={6}> Others: {patientHistoryInfo[0]?.familyHistories[0]?.others}</td>
                            </tr>}

                        </tbody>
                    </table>
                </div>
            </div> */}


        </div>
    )
}

export default PatientHistory
