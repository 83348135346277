import './App.css';
import AppRoutes from './routes/app-routes';
import "./assets/scss/common.scss"
import "./assets/scss/partials/typography.scss"
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 

function App() {
 
  return (
    <div className="App">
      <AppRoutes/>
    </div>
  );
}

export default App;
