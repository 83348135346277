import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { getWithParams } from '../../utils/fetchAPI';
import { showErrorToast } from '../../utils/toaster-alert';
import { DateRange } from "react-date-range";

export default function AppointmentSection({ phoneNumbers, setPhoneNumbers, selectAll, setSelectAll, resetAllState, makeNumberArray }) {

    const { user, userRoles, selectedRole } = useSelector((state) => state.auth);
    const [allAppointments, setAllAppointments] = useState([])
    const [status, setStatus] = useState('completed')
    const [all, setAll] = useState(false)
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]);



    useEffect(() => {
        if (selectedRole?._id && dateRange[0]?.startDate && dateRange[0]?.endDate) {
            getAppointments(selectedRole?._id, user?._id, status, dateRange[0]?.startDate, dateRange[0]?.endDate)
        }
    }, [])

    function formatDate(date) {
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0'); 
        const day = String(inputDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }


    const getAppointments = async (roleId, doctorId, status = "completed", startDate = dateRange[0]?.startDate, endDate = dateRange[0]?.endDate, per_page = 2000,) => {
        await getWithParams(`appointment/list`, {
            doctorId: doctorId,
            startDate:formatDate(startDate) ,
            endDate: formatDate(endDate),
            per_page: 2000,
            current_page: 1,
            userRoleId: roleId,
            status: status
        })
            .then((res) => {
                if (res?.status === 200) {
                    setAllAppointments(res?.data)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const setStatusFun = (value) => {
        let currentStatus = value || ""
        if (status == value) {
            setStatus("")
            currentStatus = ""
        }
        else setStatus(value)
        setPhoneNumbers([])
        setAll(false)
        getAppointments(selectedRole?._id, user?._id, currentStatus)
    }


    const selectAllAppointnments = (value, allAppointments) => {
        setAll(true)
        const phoneSet = new Set([]);
        if (value == true) {
            if (allAppointments?.appointments?.length > 0) {
                allAppointments?.appointments?.map((data) => {
                    if (data?.patientPhone?.length == 11)
                        phoneSet.add(data?.patientPhone);
                })
            }
            setPhoneNumbers([...phoneSet]);
        }
        else setPhoneNumbers([])
    }

    const dateFilter = (ranges) => {
        setDateRange([ranges.selection])
        const startDate = ranges.selection?.startDate || ""
        const endDate = ranges.selection?.endDate || ""
        if (startDate && endDate)
            getAppointments(selectedRole?._id, user?._id, status, startDate, endDate)
    }



    return (
        <div style={{ overflowY: "scroll" }}>
            <div>
                <div className='px-2 date-filter border py-2 d-flex flex-wrap'>
                    {/* <p className='p-0 m-0 title text-center'>Status Filter {status && <FontAwesomeIcon onClick={() => filterStatusFun('reSet')} size='xs' className='ml-1 rest' icon={faArrowsRotate} />}</p> */}
                    <div className='mr-2' >
                        <input onChange={() => {
                            setStatusFun("present")
                        }
                        } checked={status == "present" ? true : false} id='present' name="present" type='checkbox'></input>
                        <label htmlFor='present'>Present</label>
                    </div>
                    <div className='mr-2' >
                        <input onChange={() => {
                            setStatusFun("pending")
                        }} checked={status == "pending" ? true : false} id='Pending' name="Pending" type='checkbox'></input>
                        <label htmlFor='Pending'>Pending</label>
                    </div>
                    <div className='mr-2' >
                        <input onChange={() => {
                            setStatusFun("completed")
                        }} checked={status == "completed" ? true : false} id='Completed' name="Completed" type='checkbox'></input>
                        <label htmlFor='Completed'>Completed</label>
                    </div>
                    <div className='mr-2'>
                        <input onChange={() => {
                            setStatusFun("canceled")
                        }} checked={status == "canceled" ? true : false} id='Canceled' name="Canceled" type='checkbox'></input>
                        <label htmlFor='Canceled'>Canceled</label>
                    </div>
                </div>
                <DateRange
                    ranges={dateRange}
                    onChange={(ranges) => dateFilter(ranges)}
                    moveRangeOnFirstSelection={false}
                    rangeColors={["#17A980"]}
                />
            </div>
            {
                <div>
                    <div className='d-flex justify-content-between  border-bottom' style={{ background: '#17A980' }}>
                        <div className='py-1 mx-3'>
                            <p className='my-0 mr-2 text-white bolder' style={{ fontSize: "13px" }} >Appointment list </p>
                        </div>
                        <input
                            type='checkbox'
                            className='me-2 mx-3'
                            value={"all"}
                            checked={all}
                            onChange={(e) => {
                                selectAllAppointnments(e.target.checked, allAppointments)
                            }}
                        />
                    </div>

                    {
                        allAppointments?.appointments?.length > 0 ? allAppointments?.appointments?.map((appointment, index) => {
                            return <div key={index} className='individual-name d-flex justify-content-between px-3' style={{ cursor: "pointer" }} onClick={(e) => {
                                if (appointment?.patientPhone) {
                                    makeNumberArray(appointment?.patientPhone)
                                }
                                else showErrorToast("no number found")
                            }}>
                                <div className='py-1'>
                                    <p className='my-0'>{appointment?.patientName}</p>
                                    <p className='my-0'>{appointment?.patientPhone}</p>
                                </div>
                                <input
                                    type='checkbox'
                                    className='me-2'
                                    disabled={selectAll}
                                    id={`patient-${appointment?.id}`}
                                    value={appointment?.patientPhone}
                                    checked={phoneNumbers?.includes(appointment?.patientPhone)}
                                    onChange={(e) => {
                                        makeNumberArray(e.target.value)
                                    }}
                                />
                            </div>
                        }) : <div className='py-1'>
                            <p className='my-0 mx-2' style={{ fontSize: "13px" }}>No appointments</p>
                        </div>
                    }
                </div>
            }
        </div>
    )
}
