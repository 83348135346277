import React, { useEffect, useMemo, useRef, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { Toast } from "../../../utils/SwalUti";
import { checkDobUnit, convertAgeToDob, convertDobToAge } from "../../../utils/common-funtions/common";
import SelectWithLocalSearch from "../../common/updated-input/select-with-local-search/select-with-local-search";
import { get, post, put } from "../../../utils/fetchAPI";
import CreatableSearchSelect from "../../common/updated-input/createble-search-select/creatable-search-select";


const PatientDemography = ({ visitDetails, patientId, patientDetails, updateState, refresh, updateVisit }) => {
    const [info, setInfo] = useState({});
    const [updatedInfo, setUpdatedInfo] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [patientInfo, setPatientInfo] = useState(false);
    const [referral, setReferral] = useState("")
    const dispatch = useDispatch();
    const timeoutIdRef = useRef(null)
    const [referralList, setReferralList] = useState([])
    const [age, setAge] = useState({
        age: 0,
        ageUnit: "year(s)"
    })

    const maritalStatusOptions = [
        {
            id: 1,
            name: "Married",
            value: "married"
        },
        {
            id: 2,
            name: "Unmarried",
            value: "unmarried"
        },
    ]

    useEffect(() => {
        if (visitDetails?.patient || patientId) {
            if (patientDetails)
                setPatientInfo(patientDetails)
            else
                getPatientDetails(visitDetails?.patient || patientId)
        }
        if (patientDetails) {
            setPatientInfo(patientDetails)
        }
        if (visitDetails?.patient) setReferral(visitDetails?.referredBy)
        if (visitDetails?.doctor) getRefferalList(visitDetails?.doctor)

    }, [visitDetails?.patient, patientId, patientDetails])


    function getPatientDetails(patientId) {
        get(`/patient/${patientId}`)
            .then((res) => {
                if (res?.status === 200) {
                    setPatientInfo(res.data)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    //
    // const refresh = () => {
    //     dispatch(getPatientDetails(patientId));
    // }


    useMemo(() => {
        if (patientInfo?.dob){
            let currentAge = convertDobToAge(patientInfo?.dob, checkDobUnit(patientInfo?.dob)) || ""
            let splitCurrentAge = currentAge?.split(" ")
            setAge({
                age: splitCurrentAge[0], ageUnit: splitCurrentAge[1]
            })
            setInfo(patientInfo)
        }
    }, [patientInfo])

    const onSubmit = async (values, { resetForm }) => {
        values = updatedInfo;
        if (values) {
            setIsSubmitting(true);
            await post(`/patient/update/${info?.patientId}`, values)
                .then((res) => {
                    if (res.status === 200) {
                        setIsSubmitting(false);
                        appointmentUpdate(values)
                        updateState(res?.data)
                        Toast.fire({
                            icon: '',
                            title: "Patient updated successfully"
                        })
                    }
                })
                .catch((error) => {
                    setIsSubmitting(false);
                });
        }
    };


    const appointmentUpdate = async (values) => {
        let payload = {}
        if (values?.name) payload.patientName = values?.name
        if (values?.gender) payload.patientGender = values?.gender
        if (values?.dob) payload.patientDob = values?.dob
        if (values?.phone) payload.patientPhone = values?.phone
        if (referral) {
            payload.referredBy = referral
            handleReferralUpdate(referral)
        }
        await put(`appointment/update/${visitDetails?.appointment}`, payload)
            .then((res) => {
                if (res.status === 200) {
                    // refresh()
                }
            })
            .catch((error) => {

            });
    };

    const handleReferralUpdate = async (referredBy) => {
        await put(`/visit/${visitDetails?._id}`, { referredBy: referredBy })
            .then((res) => {
                if (res.status === 200) {
                    updateVisit(res?.data)
                    setReferral(res?.data?.referredBy)
                }
            })
            .catch((e) => { })
            .finally(() => { })
    }


    const setInfoFun = (key, value) => {
        let temp = { ...info };
        setInfo({ ...temp, [key]: value });
        setUpdatedInfo({ ...updatedInfo, [key]: value })
    };

    const setDobAgeInfoFun = (key, value) => {
        let temp = { ...info };
        if (key == "dob") {
            let currentAge = convertDobToAge(value, age?.ageUnit)
            setInfo({ ...temp, dob: value });
            setUpdatedInfo({ ...updatedInfo, dob: value })
            let splitCurrentAge = currentAge?.split(" ")
            setAge({
                age: splitCurrentAge[0], ageUnit: splitCurrentAge[1]
            })
        } else if (key == "ageUnit") {
            setInfo({ ...temp, dob: convertAgeToDob(age?.age, value), age: age?.age + " " + value });
            setUpdatedInfo({ ...updatedInfo, dob: convertAgeToDob(age?.age, value) })
            setAge({ ...age, ageUnit: value })
        } else {
            setInfo({ ...temp, dob: convertAgeToDob(value, age?.ageUnit), age: value + " " + age?.ageUnit });
            setUpdatedInfo({ ...updatedInfo, dob: convertAgeToDob(value, age?.ageUnit) })
            setAge({ ...age, age: value })
        }
    };





    const setRefferalListFun = (event) => {
        event.preventDefault()
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { (getRefferalList(visitDetails?.doctor, event?.target?.value)) }, 800)

    }
    const handleAddToPrescription = (value) => {
        setReferral(referral + (referral?.length >= 2 ? "\n" : "") + value?.details)
    }
    const handleCreateReferral = (value) => {
        setReferral(referral + (referral?.length >= 2 ? "\n" : "") + value)
        addReferral(visitDetails?.doctor, value)

    }

    const getRefferalList = async (doctorId = visitDetails?.doctor, search = "") => {
        await get(`/doctor/referral/${doctorId}?search=${search}`)
            .then((res) => {
                if (res?.status == 200) {
                    setReferralList(res?.data)
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });
    }

    const addReferral = async (doctorId, details) => {
        await post(`/doctor/referral/create`, {
            "doctorId": doctorId,
            "details": details
        })
            .then((res) => {
                if (res?.status == 200) {
                    getRefferalList()
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }


    return (
        <Formik
            initialValues={{
                name: "",
                phone: "",
                email: "",
                gender: "",
                dob: "",
                bloodGroup: "",
                maritalStatus: ""
            }}
            onSubmit={onSubmit}
        >
            {(formik) => (
                <div className="appointment-create-form-section pb-0 px-4">
                    <Form
                        action=""
                        id=""
                        className="custom-form input-section position-relative"
                        encType="multipart/form-data"
                    >
                        <div className="row mt-0 pt-0">
                            <p className="mx-2 mt-0 pt-0 ml-2 border-bottom mb-4" style={{ fontSize: "14px", fontWeight: "600" }}>Patient information</p>
                            <div className="col-12 my-0 mx-1 pb-0   d-flex" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label py-0 my-0" style={{ width: '40%' }}>
                                    Name<span className="text-danger">*</span> :
                                </label>
                                <input
                                    required
                                    className="form-control input py-0 my-0"
                                    autoComplete="off"
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={info?.name || ""}
                                    placeholder="Enter Name"
                                    //   defaultValue={info?.name}
                                    onChange={(e) => {
                                        setInfoFun("name", e.target.value);
                                    }}
                                />
                                <br />
                            </div>
                            <div className="col-12 my-0 py-0 mx-1 mb-1 d-flex mb-1">
                                <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                                    Patient Id :
                                </label>
                                <input
                                    className="form-control input"
                                    autoComplete="off"
                                    type="string"
                                    id="age"
                                    name="age"
                                    disabled
                                    placeholder="Patient id"
                                    value={info?.patientId || ""}
                                    onChange={(e) => {
                                        setInfoFun("patientId", e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-12  d-flex my-0 py-0 mx-1 mb-1" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                                    Phone Number:
                                </label>
                                <input
                                    className="form-control input"
                                    autoComplete="off"
                                    pattern="(^(01){1}[3-9]{1}\d{8})$"
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    placeholder="Enter Phone Number"
                                    value={info.phone || ""}
                                    onChange={(e) => {
                                        setInfoFun("phone", e.target.value);
                                    }}
                                />
                                <br />
                                {/* <ErrorMessage name='firstName'></ErrorMessage> */}
                            </div>

                            <div className="col-12 my-2 mx-1 pb-0   d-flex" style={{ minHeight: '60px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label py-0 my-0"
                                    style={{ width: '40%' }}>
                                    Referred by :
                                </label>
                                <div className="w-100">
                                    <CreatableSearchSelect options={referralList} setOptionFun={setRefferalListFun} handleSubmit={handleAddToPrescription}
                                        handleCreate={handleCreateReferral}
                                    ></CreatableSearchSelect>
                                    <textarea
                                        rows={4}
                                        className="form-control input py-0 my-0 px-1 mt-2"
                                        autoComplete="off"
                                        type="text"
                                        style={{ fontSize: "13px" }}
                                        id="name"
                                        name="name"
                                        value={referral || ""}
                                        placeholder="Referred by"
                                        //   defaultValue={info?.name}
                                        onChange={(e) => {
                                            setReferral(e.target.value);
                                        }}
                                    />
                                </div>
                                <br />
                            </div>

                            <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                                    Email:
                                </label>
                                <input
                                    className="form-control input"
                                    autoComplete="off"
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter Email"
                                    value={info?.email || ""}
                                    onChange={(e) => {
                                        setInfoFun("email", e.target.value);
                                    }}
                                />
                                <br />
                                {/* <ErrorMessage name='firstName'></ErrorMessage> */}
                            </div>

                            <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                                    Age<span className="text-danger">*</span>:
                                </label>
                                <input
                                    style={{ width: '85%' }}
                                    required
                                    className="form-control input"
                                    autoComplete="off"
                                    type="number"
                                    id="age"
                                    name="age"
                                    placeholder="Enter age"
                                    value={age?.age || ""}
                                    onChange={(e) => {
                                        setDobAgeInfoFun("age", e.target.value);
                                    }}
                                />
                                <select
                                    onChange={(e) => {
                                        setDobAgeInfoFun("ageUnit", e.target.value);
                                    }}
                                    value={age?.ageUnit || ""}
                                >
                                    <option value={'year(s)'}>year(s)</option>
                                    <option value={'month(s)'}>month(s)</option>
                                    <option value={'day(s)'}>day(s)</option>
                                </select>{" "}
                                <br />
                            </div>

                            <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                                    Date of birth <span className="text-danger">*</span> : <br></br> <small className="text-secondary">(dd/mm/yyyy)</small>
                                </label>
                                <input
                                    required
                                    className="form-control input"
                                    autoComplete="off"
                                    type="date"
                                    id="dob"
                                    name="dob"
                                    placeholder="Enter appointmentDate"
                                    value={info?.dob?.slice(0, 10) || ""}
                                    onChange={(e) => {
                                        setDobAgeInfoFun("dob", e.target.value);
                                    }}
                                />
                                <br />
                            </div>

                            <div className="col-12 my-2 mx-1 pb-0   d-flex" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label py-0 my-0" style={{ width: '40%' }}>
                                    Address :
                                </label>
                                <textarea
                                    rows={2}
                                    className="form-control input py-0 my-0 px-1"
                                    autoComplete="off"
                                    type="text"
                                    id="name"
                                    style={{ fontSize: "13px" }}
                                    name="name"
                                    value={info?.address || ""}
                                    placeholder="Enter address"
                                    //   defaultValue={info?.name}
                                    onChange={(e) => {
                                        setInfoFun("address", e.target.value);
                                    }}
                                />
                                <br />
                            </div>

                            <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                                    Blood Group :
                                </label>
                                {/* <input
                                    className="form-control input"
                                    autoComplete="off"
                                    type="string"
                                    id="age"
                                    name="age"
                                    placeholder="Blood Group"
                                    value={info?.bloodGroup || ""}
                                    onChange={(e) => {
                                        setInfoFun("bloodGroup", e.target.value);
                                    }}
                                /> */}
                                <select
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        setInfoFun("bloodGroup", e.target.value);
                                    }}
                                    value={info?.bloodGroup || ""}
                                >
                                    <option value={""}>Select</option>
                                    <option value={'A+'}>A+</option>
                                    <option value={'A-'}>A-</option>
                                    <option value={'B+'}>B+</option>
                                    <option value={'B-'}>B-</option>
                                    <option value={'AB+'}>AB+</option>
                                    <option value={'AB-'}>AB-</option>
                                    <option value={'O+'}>O+</option>
                                    <option value={'O-'}>O-</option>
                                </select>{" "}
                            </div>
                            <div className="col-12 mb-1" style={{ height: '30px' }}>
                                <div className="mx-1 d-flex">
                                    <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '29%' }}>
                                        Gender<span className="text-danger">*</span>:
                                    </label>
                                    <div
                                        role="group"
                                        aria-labelledby="my-radio-group"
                                        style={{ fontSize: "20px" }}
                                        className="d-flex px-0 mx-0"
                                    >
                                        <label className=" d-flex mr-3">
                                            <input
                                                checked={
                                                    info?.gender?.toLowerCase() === "male" ? true : false
                                                }
                                                type="radio"
                                                name="gender"
                                                value="Male"
                                                required={info?.gender?.length > 0 ? false : true}
                                                onClick={() => {
                                                    setInfoFun("gender", "Male");
                                                }}
                                            />
                                            <span className="ml-2 mt-1">Male</span>
                                        </label>
                                        <label className="m-0 p-0 d-flex">
                                            <input
                                                checked={
                                                    info?.gender?.toLowerCase() === "female"
                                                        ? true
                                                        : false
                                                }
                                                required={info?.gender?.length > 0 ? false : true}
                                                type="radio"
                                                name="gender"
                                                value="Female"
                                                onClick={() => {
                                                    setInfoFun("gender", "Female");
                                                }}
                                            />
                                            <span className="ml-2 mt-1">Female</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mb-0 pb-0 mx-1 d-flex mb-2" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                                    Marital Status:
                                </label>
                                <div className="mt-0 pt-0 w-100 " key={3}>
                                    <SelectWithLocalSearch currentValue={info?.maritalStatus} name="maritalStatus" handleFormValues={(status) => {
                                        setInfoFun("maritalStatus", status?.value);
                                    }} isCreatable={false} options={maritalStatusOptions} placeHolder='Select marital status'></SelectWithLocalSearch>
                                </div>
                                <br />
                            </div>

                            <div className="col-12  text-center mt-3 d-flex">
                                <button
                                    type="submit"
                                    className="btn submit-button submit-button-position mx-auto w-25"
                                    onClick={() => {
                                    }}
                                >
                                    Save
                                    {isSubmitting && (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
};


export default PatientDemography
