import React, { useEffect, useRef, useState } from 'react'
import { get, post } from '../../../utils/fetchAPI';
import CreatableSearchSelect from '../../common/updated-input/createble-search-select/creatable-search-select';

export const ReferralHeader = ({ setReferralInstruction, handleReferral, setReferralModal, referralModal, referralInstruction, visitDetails }) => {
    const [referralList, setReferralList] = useState([])
    const timeoutIdRef = useRef(null)

    useEffect(() => {
        getRefferalList(visitDetails?.doctor)
    }, [])


    const setRefferalListFun = (event) => {
        event.preventDefault()
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { (getRefferalList(visitDetails?.doctor, event?.target?.value)) }, 800)

    }
    const handleAddToPrescription = (value) => {
        setReferralInstruction(referralInstruction + (referralInstruction?.length >= 2 ? "\n" : "") + value?.details)
    }
    const handleCreateReferral = (value) => {
        setReferralInstruction(referralInstruction + (referralInstruction?.length >= 2 ? "\n" : "") + value)
        addReferral(visitDetails?.doctor, value)

    }

    const getRefferalList = async (doctorId = visitDetails?.doctor, search = "") => {
        await get(`/doctor/referral/${doctorId}?search=${search}`)
            .then((res) => {
                if (res?.status == 200) {
                    setReferralList(res?.data)
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });
    }

    const addReferral = async (doctorId, details) => {
        await post(`/doctor/referral/create`, {
            "doctorId": doctorId,
            "details": details
        })
            .then((res) => {
                if (res?.status == 200) {
                    getRefferalList()
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    return (
        <div>
            <div className='mt-0  create-template px-2 py-2'>
                <label className='mt-0 pt-0 d-block'>Write referral</label>
                <CreatableSearchSelect options={referralList} setOptionFun={setRefferalListFun} handleSubmit={handleAddToPrescription}
                    handleCreate={handleCreateReferral}
                ></CreatableSearchSelect>
                <textarea
                    className="form-control w-100 mt-3"
                    autoComplete="off"
                    type="text"
                    style={{ fontSize: "13px", padding: "4px" }}
                    rows={4}
                    placeholder="reason"
                    value={referralInstruction || ""}
                    onChange={(e) => {
                        // setInfoFun("reasonForVisit", e.target.value);
                        setReferralInstruction(e.target.value)
                    }}
                />
                <div className='text-center mt-2'>
                    <button onClick={() => {
                        handleReferral()
                    }} className='btn py-0 mr-1'>Save
                    </button>
                    <button onClick={() => setReferralModal(!referralModal)} className='btn py-0'>Cancel</button>
                </div>
            </div>
        </div>
    )
}
