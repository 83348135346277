import React, { useEffect, useMemo, useRef, useState } from 'react'
import './advice.scss'
import CreatableSearchSelect from '../../../common/updated-input/createble-search-select/creatable-search-select'
import { useDispatch, useSelector } from 'react-redux';
import { getAllAdvices } from '../../../../store/slices/prescriptionSlice';
import { patientAdviceAdd } from '../../../../service/api/patient-api';
import { addDoctorAdvice } from '../../../../service/api/medical-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { prescriptionAddSubDocument, prescriptionRemoveSubDocument, prescriptionUpdateSubDocument } from '../../../../service/api/prescription';
import {
  templateAddSubDocument,
  templateRemoveSubDocument,
  templateUpdateSubDocument
} from '../../../../service/api/template';
import UniversalLoaderForComponent from '../../../loader/universalLoaderForComponent';
import { put } from '../../../../utils/fetchAPI';
import CreatableSearchSelectAntD from '../../../common/updated-input/createble-search-select/creatable-search-select-antd';

const Advice = ({ visitDetails, doctorId = "", templateId = "", updateState }) => {
  let { adviceList, isAdviceListLoading } = useSelector((state) => state.prescription);
  let { templateDetails } = useSelector((state) => state.template);
  const [options, setOptions] = useState([])
  const [prescribedAdvices, setPrescribedAdvices] = useState([])
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  let [details, setDetails] = useState()
  let timeoutIdRef = useRef()

  useEffect(() => {
    if (doctorId || visitDetails?.doctor) {
      const payLoad = { doctorId: visitDetails?.doctor || doctorId, search: '' }
      dispatch(getAllAdvices(payLoad))
    }
  }, [visitDetails?.doctor, doctorId])


  const setOptionFun = (event) => {
    event.preventDefault()
    const payLoad = { doctorId: visitDetails?.doctor || doctorId, search: event.target.value }
    dispatch(getAllAdvices(payLoad))
  }

  const handleSubmit = (value) => {
    adviceAddFun(value?._id, value?.value)
  }

  const handleCreate = async (value) => {
    await addDoctorAdvice({ doctorId: visitDetails?.doctor || doctorId, details: value })
      .then((res) => {
        if (res.status === 200) {
          adviceAddFun(res?.data?._id, res?.data?.details)
          const payLoad = { doctorId: visitDetails?.doctor || doctorId, search: '' }
          dispatch(getAllAdvices(payLoad))
        }
      })
  }

  const adviceAddFun = async (adviceId, details) => {
    setIsLoading(true)
    if (!templateId) {
      const payLoad = {
        advice: adviceId,
        details: details
      }
      await prescriptionAddSubDocument('advices', payLoad, visitDetails?._id)
        .then((res) => {
          if (res.status === 200) {
            // prescriptionRefresh()
            setPrescribedAdvices(res?.data?.advices)
            updateState(res?.data)
          }
        }).finally(() => { setIsLoading(false) })
    }
    else if (templateId) {
      const payLoad = {
        advice: adviceId,
        details: details
      }
      if (templateId) {
        await templateAddSubDocument('advices', payLoad, templateId)
          .then((res) => {
            if (res.status === 200) {
              setPrescribedAdvices(res?.data?.advices)
            }
          }).finally(() => { setIsLoading(false) })
      }
    }
  }

  const deleteAdviceFun = async (advice) => {
    setIsLoading(true)
    if (!templateId) {
      await prescriptionRemoveSubDocument('advices', { _id: advice?._id }, visitDetails?._id)
        .then((res) => {
          if (res?.status === 200) {
            setPrescribedAdvices(res?.data?.advices)
            updateState(res?.data)
          }
        }).finally(() => { setIsLoading(false) })
    }

    else if (templateId) {

      await templateRemoveSubDocument('advices', { _id: advice?._id }, templateId)
        .then((res) => {
          if (res?.status === 200) {
            setPrescribedAdvices(res?.data?.advices)
          }
        }).finally(() => { setIsLoading(false) })
    }
  }

  useMemo(() => {
    if (visitDetails?.advices) {
      setPrescribedAdvices(visitDetails?.advices)
    } else if (templateId) {
      setPrescribedAdvices(templateDetails?.advices)
    }
  }, [visitDetails?.advices, templateDetails])

  const adviceEdit = (value, advice, index) => {

    let oldAdvices = [...prescribedAdvices]
    let current = {}
    if (oldAdvices[index])
      current = oldAdvices[index]
    current = { ...current, details: value }
    oldAdvices[index] = current
    setPrescribedAdvices([...oldAdvices])
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(async () => {

      let payLoad = {}
      payLoad._id = advice?._id
      payLoad[`advices.$.details`] = value

      if (templateId) {
        await templateUpdateSubDocument("advices", payLoad, templateId).
          then((res) => {
            if (res.status == 200) {
              setPrescribedAdvices(res?.data?.advices)
              updateDoctorAdvice(advice?.advice, value, visitDetails?.doctor || doctorId)
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally()
      }
      else {
        await prescriptionUpdateSubDocument("advices", payLoad, visitDetails?._id).
          then((res) => {
            if (res.status == 200) {
              setPrescribedAdvices(res?.data?.advices)
              updateState(res?.data)
              updateDoctorAdvice(advice?.advice, value, visitDetails?.doctor || doctorId)
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally()
      }

    }, 1000);
  }

  const updateDoctorAdvice = async (adviceId, details, doctorId) => {
    await put(`doctor/advice/update`, { adviceId: adviceId, details: details, doctorId: doctorId }).
      then((res) => {
        if (res.status == 200) {
          const payLoad = { doctorId: visitDetails?.doctor || doctorId, search: '' }
          dispatch(getAllAdvices(payLoad))
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally()
  }


  useMemo(() => {
    let allAdvices = []
    if (adviceList?.length > 0) {
      adviceList?.map((data) => {
        allAdvices.push({ value: JSON.stringify({ _id: data?._id, value: data?.details }), label: data?.details })
      })
      setOptions(allAdvices)
    }
  }, [adviceList])

  return (
    <div className='advice-section'>
      <div className='mx-2'>
        <CreatableSearchSelectAntD customClass='custom-color-border-1' placeHolderColor="#13AC81" handleSubmit={handleSubmit} handleCreate={handleCreate} isSearching={isAdviceListLoading} setOptionFun={setOptionFun} options={options} placeHolder='Search advice'></CreatableSearchSelectAntD>
      </div>
      <div>
        <div className='mt-2 added-advice-section position-relative'>
          {prescribedAdvices?.length > 0 ? prescribedAdvices?.map((advice, index) => {
            return (
              <div key={index} className='d-flex justify-content-between individual-advice'>
                <div className='ml-1 mb-1' style={{ width: "92%" }}>
                  <textarea
                    className="form-control input"
                    autoComplete="off"
                    id={`advice-${advice?._id}`}
                    name="address"
                    value={advice?.details || ""}
                    placeholder="Enter address"
                    rows={2}
                    onChange={(e) => {
                      adviceEdit(e.target.value, advice, index)
                    }}
                  />
                </div>
                <div className='my-auto'>
                  <span onClick={() => {
                    deleteAdviceFun(advice)
                  }} className='icon'> <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></span>
                </div>
              </div>
            )
          }) : ""}
          {isLoading && <UniversalLoaderForComponent></UniversalLoaderForComponent>}
        </div>
      </div>
    </div >
  )
}

export default Advice
