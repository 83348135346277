import React, { useEffect, useMemo, useRef, useState } from 'react'
import './complaints.scss'
import { Form, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { del, get, post, put } from '../../../../utils/fetchAPI';
import { showSuccessToast } from '../../../../utils/toaster-alert';
import CreatableSearchSelect from '../../../common/updated-input/createble-search-select/creatable-search-select';
import CustomModal from '../../../common/modal/modal';
import { useSelector } from 'react-redux';

const Complaint = ({ doctorId , templateId }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [info, setInfo] = useState({});
    const [showAdd, setShowAdd] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [templateInfo, setTemplateInfo] = useState({})
    const [templateList, setTemplateList] = useState([])
    const [presentIllnessList, setPresentIllnessList] = useState([])
    const timeoutIdRef = useRef(null)
    const [showTemplates, setShowTemplates] = useState(false)

    const [showPresentIllness, setShowPresentIllness] = useState(false)
    const [showPresenetDelete, setShowPresenetDelete] = useState(false)
    let {templateDetails } = useSelector((state) => state.template);
    let [data,setData]=useState({})

    useEffect(() => {
        if (templateDetails) {
            getTemplateList(templateDetails?.doctor)
            getPresentIllnessList(templateDetails?.doctor)
            setData(templateDetails)
        }
    }, [templateDetails])

    // Reason For Visit  
    const setReasonForVisitOptionFun = (event) => {
        event.preventDefault()
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { (getTemplateList(data?.doctor, event?.target?.value)) }, 500)

    }
    const handleReasonForVisitAddSubmit = (value) => {
        let data = { ...info }
        let updatedReasonForVisit = ((data?.reasonForVisit || "") + (data?.reasonForVisit ? "\n" : "") + (value?.reasonForVisit || ""))
        onSubmit({ reasonForVisit: updatedReasonForVisit, historyOfPresetIllness: data?.historyOfPresetIllness, extraNote: data?.extraNote })
    }
    const handleCreatePastIlless = (value) => {
        let data = { ...info }
        let updatedReasonForVisit = ((data?.reasonForVisit || "") + (data?.reasonForVisit ? "\n" : "") + (value || ""))
        onSubmit({ reasonForVisit: updatedReasonForVisit, historyOfPresetIllness: data?.historyOfPresetIllness, extraNote: data?.extraNote })
        createNewComplaintTemplate(value)
    }
    // -----------------

    // Presnet Illness  
    const setPresentIllnessOptionFun = (event) => {
        event.preventDefault()
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { (getPresentIllnessList(data?.doctor, event?.target?.value)) }, 500)

    }
    const handlePresentIllnessAddSubmit = (value) => {
        let data = { ...info }
        let updatedPresentIlness = ((data?.historyOfPresetIllness || "") + (data?.historyOfPresetIllness ? "\n" : "") + (value?.historyOfPresetIllness || ""))
        onSubmit({ reasonForVisit: data?.reasonForVisit, historyOfPresetIllness: updatedPresentIlness, extraNote: data?.extraNote })
    }
    const handleCreatePresentIllness = (value) => {
        let data = { ...info }
        let updatedPresentIlness = ((data?.historyOfPresetIllness || "") + (data?.historyOfPresetIllness ? "\n" : "") + (value || ""))
        onSubmit({ reasonForVisit: data?.reasonForVisit, historyOfPresetIllness: updatedPresentIlness, extraNote: data?.extraNote })
        createNewpresenetIllnessTemplate(value)
    }
    // -----------------


    const setInfoFun = (key, value, submit = false) => {
        let temp = { ...info, [key]: value };
        setInfo(temp);
        if (submit) {
            onSubmit(temp)
        }
    };



   
       const onSubmit = async (temp) => {
        
        const payLoad = {
            reasonForVisit: temp.reasonForVisit || "",
            historyOfPresetIllness: temp.historyOfPresetIllness || "",
            extraNote: temp.extraNote || ""
        }
        // setIsSubmitting(true)
        await put(`doctor/template/${templateId}`, payLoad)
            .then((res) => {
                if (res?.status == 200) {
                    setIsSubmitting(false);
                    setData(res?.data)
                    // prescriptionRefresh()
                }
            })
            .catch((error) => {
                setIsSubmitting(false);
                console.log(error);
            });

    };

    const handleKeyDown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 's') {
            event.preventDefault();
            let values = {}
            values.reasonForVisit = info?.reasonForVisit;
            values.historyOfPresetIllness = info?.historyOfPresetIllness;
            values.extraNote = info?.extraNote
            onSubmit(values)

        }
    }
    useEffect(() => {
        if (info)
            document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [info]);


    const createNewComplaintTemplate = async (value = info?.reasonForVisit) => {
        await post(`/doctor/complaints`, { reasonForVisit: value })
            .then((res) => {
                if (res?.status == 200) {
                    getTemplateList(data?.doctor)
                    showSuccessToast("Saved as template")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const createNewpresenetIllnessTemplate = async (value = info?.historyOfPresetIllness) => {

        await post(`/doctor/presentIllness`, { historyOfPresetIllness: value })
            .then((res) => {
                if (res?.status == 200) {
                    getPresentIllnessList(data?.doctor)
                    showSuccessToast("Saved as template")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }
    const getTemplateList = async (doctorId, search = "") => {

        await get(`/doctor/complaints/list/${doctorId}${search ? (`?search=${search}`) : ""}`)
            .then((res) => {
                if (res?.status == 200) {
                    setTemplateList(res?.data)
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }
    const getPresentIllnessList = async (doctorId, search = "") => {

        await get(`/doctor/presentIllness/list/${doctorId}${search ? (`?search=${search}`) : ""}`)
            .then((res) => {
                if (res?.status == 200) {
                    setPresentIllnessList(res?.data)
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const updateTemplate = async (type) => {
        let { historyOfPresetIllness, ...payload } = templateInfo
        await put(`/doctor/${type}/update`, payload)
            .then((res) => {
                if (res?.status == 200) {
                    getTemplateList(data?.doctor)
                    setTemplateInfo({})
                    setShowAdd(false)
                    showSuccessToast("Updated successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const updatePresnetIllness = async () => {
        let { reasonForVisit, ...payload } = templateInfo
        await put(`/doctor/presentIllness/update`, payload)
            .then((res) => {
                if (res?.status == 200) {
                    getPresentIllnessList(data?.doctor)
                    setTemplateInfo({})
                    setShowPresentIllness(false)
                    showSuccessToast("Updated successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const deleteTemplate = async (type, id) => {
        await del(`/doctor/${type}/delete/${id}`,)
            .then((res) => {
                if (res?.status == 200) {
                    getTemplateList(data?.doctor)
                    setTemplateInfo({})
                    setShowDelete(false)
                    showSuccessToast("Deleted successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const deletePresentIllness = async (id) => {
        await del(`/doctor/presentIllness/delete/${id}`,)
            .then((res) => {
                if (res?.status == 200) {
                    getPresentIllnessList(data?.doctor)
                    setTemplateInfo({})
                    setShowPresenetDelete(false)
                    showSuccessToast("Deleted successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    useMemo(() => {
                setInfo(
                    {
                        reasonForVisit: data?.reasonForVisit || "",
                        historyOfPresetIllness: data?.historyOfPresetIllness || "",
                        extraNote: data?.extraNote || "",
                    }
                )
    }, [data])


    return (
        <div className='template-complaints'>
            <div className='complaint-section'>
                {data && <Formik initialValues={{}} onSubmit={onSubmit}>
                    {(formik) => (
                        <div className="">
                            <Form
                                action=""
                                id=""
                                className="custom-form input-section position-relative"
                                encType="multipart/form-data"
                            >

                                <div className="row ">
                                    <div className="col-6 col-md-12  mb-0 pb-0 ">
                                        <CreatableSearchSelect placeHolder='Search Reason For Visit' options={templateList} setOptionFun={setReasonForVisitOptionFun} handleSubmit={handleReasonForVisitAddSubmit}
                                            handleCreate={handleCreatePastIlless}
                                        ></CreatableSearchSelect> 
                                        <div className='w-100 d-flex pt-3  justify-content-between'>
                                            <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                                                Reason For Visit
                                            </label>
                                            <div className='d-flex'>
                                               <div>
                                               <p onClick={() => {
                                                    createNewComplaintTemplate()
                                                }} className='bg-success text-end px-2 rounded mx-2 pointer' style={{ cursor: "pointer" }}>Save as template</p>
                                               </div>
                                                <FontAwesomeIcon onClick={() => { setShowTemplates(true) }} style={{ fontSize: "20px", color: "12ab81", cursor: "pointer" }} icon={faGear} />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            {" "}
                                            <textarea
                                                className="form-control  w-100"
                                                autoComplete="off"
                                                type="text"
                                                rows={3}
                                                placeholder="Reason For Visit"
                                                value={info?.reasonForVisit || ""}
                                                onChange={(e) => {
                                                    setInfoFun("reasonForVisit", e.target.value);
                                                }}
                                                onBlur={(e) => {
                                                    setInfoFun("reasonForVisit", e.target.value, true);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-12  mb-0 pb-0 mt-4">
                                        <CreatableSearchSelect placeHolder='Search History Of Present Illness' options={presentIllnessList} setOptionFun={setPresentIllnessOptionFun} handleSubmit={handlePresentIllnessAddSubmit}
                                            handleCreate={handleCreatePresentIllness}
                                        ></CreatableSearchSelect>
                                        <div className='w-100 d-flex pt-3  justify-content-between'>
                                            <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                                                History Of Present Illness
                                            </label>
                                            <div className='d-flex'>
                                                <div>
                                                <p onClick={() => {
                                                    createNewpresenetIllnessTemplate()
                                                }} className='bg-success text-end px-2 rounded mx-2 pointer' style={{ cursor: "pointer" }}>Save as template</p>
                                                </div>
                                                <FontAwesomeIcon onClick={() => { setShowTemplates(true) }} style={{ fontSize: "20px", color: "12ab81", cursor: "pointer" }} icon={faGear} />
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            {" "}
                                            <textarea
                                                className="form-control  w-100"
                                                autoComplete="off"
                                                type="text"
                                                rows={3}
                                                placeholder="History Of Preset Illness"
                                                value={info?.historyOfPresetIllness || ""}
                                                onChange={(e) => {
                                                    setInfoFun("historyOfPresetIllness", e.target.value);
                                                }}
                                                onBlur={(e) => {
                                                    setInfoFun("historyOfPresetIllness", e.target.value, true);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-12  mb-0 pb-0 mt-2">
                                        <label htmlFor="exampleInputEmail1" className="form-label mt-2">
                                            Note
                                        </label>
                                        <div className="d-flex">
                                            {" "}
                                            <textarea
                                                className="form-control  w-100"
                                                autoComplete="off"
                                                type="text"
                                                rows={2}
                                                placeholder="Note"
                                                value={info?.extraNote || ""}
                                                onChange={(e) => {
                                                    setInfoFun("extraNote", e.target.value);
                                                }}
                                                onBlur={(e) => {
                                                    setInfoFun("extraNote", e.target.value, true);
                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>}



                <CustomModal
                    size="md"
                    show={showAdd}
                    isHeaderHide={true}
                >
                    <div className='mt-0  create-template px-2 py-2'>

                        <label className='mt-0 pt-0 d-block'>Reason For Visit</label>
                        <textarea className='w-100' style={{ fontSize: "13px", outline: "none", padding: "4px" }} rows={4} value={templateInfo?.reasonForVisit} onChange={(e) => {
                            setTemplateInfo({ reasonForVisit: e.target.value, historyOfPresetIllness: templateInfo?.historyOfPresetIllness || "", chiefCompliantId: templateInfo?.chiefCompliantId, doctorId: data?.doctor })
                        }} placeholder='Enter template name'></textarea>

                        <div className='text-center mt-2'>
                            <button onClick={() => {
                                updateTemplate("complaints")
                            }} className='btn py-0 mr-1'>Update
                            </button>

                            <button onClick={() => setShowAdd(false)} className='btn py-0'>Cancel</button>
                        </div>
                    </div>
                </CustomModal>

                <CustomModal
                    size="sm"
                    show={showDelete}
                    isHeaderHide={true}
                // setShow={setShow}
                >
                    <div className='mt-0  create-template px-2 py-2'>
                        <p>Are ypu sure?</p>
                        <div className='text-center mt-2'>
                            <button onClick={() => {
                                deleteTemplate("complaints", templateInfo?.chiefCompliantId)
                            }} className='btn py-0 mr-1 bg-danger'>delete
                            </button>
                            <button onClick={() => setShowDelete(false)} className='btn py-0'>Cancel</button>
                        </div>
                    </div>
                </CustomModal>

                <CustomModal
                    size="md"
                    show={showPresentIllness}
                    isHeaderHide={true}
                // setShow={setShow}
                >
                    <div className='mt-0  create-template px-2 py-2'>
                        <label className='mt-0 pt-0 d-block'>History of present illness</label>
                        <textarea key={12} className='w-100' style={{ fontSize: "13px", outline: "none", padding: "4px" }} rows={4} value={templateInfo?.historyOfPresetIllness} onChange={(e) => {
                            setTemplateInfo({ historyOfPresetIllness: e.target.value, historyOfPresetIllnessId: templateInfo?.historyOfPresetIllnessId, doctorId: data?.doctor })
                        }} placeholder='Enter template name'></textarea>

                        <div className='text-center mt-2'>
                            <button onClick={() => {
                                updatePresnetIllness()
                            }} className='btn py-0 mr-1'>Update
                            </button>

                            <button onClick={() => setShowPresentIllness(false)} className='btn py-0'>Cancel</button>
                        </div>
                    </div>
                </CustomModal>
                <CustomModal
                    size="sm"
                    show={showPresenetDelete}
                    isHeaderHide={true}
                // setShow={setShow}
                >
                    <div className='mt-0  create-template px-2 py-2'>
                        <p>Are ypu sure?</p>
                        <div className='text-center mt-2'>
                            <button onClick={() => {
                                deletePresentIllness(templateInfo?.presentIllnessId)
                            }} className='btn py-0 mr-1 bg-danger'>delete
                            </button>
                            <button onClick={() => setShowPresenetDelete(false)} className='btn py-0'>Cancel</button>
                        </div>
                    </div>
                </CustomModal>
                <CustomModal
                    size="xl"
                    show={showTemplates}
                    isHeaderHide={true}
                // setShow={setShow}
                >
                    <div className='p-4' >
                        <div className='d-flex justify-content-end mb-1'>
                            <button style={{ background: "red", color: "white" }} onClick={() => setShowTemplates(false)} className='btn py-0'>Close</button>
                        </div>
                        <div style={{ maxHeight: "80vh", overflowX: "scroll" }}>
                            <table className="table table-sm table-hover border" >
                                <div className='d-flex'>
                                    <div className='p-2' style={{ width: "50%", borderRight: "1px solid black", minHeight: "30vh" }}>
                                        <p style={{ fontSize: "14px", borderBottom: "1px solid black" }}> Chief Complaint</p>
                                        <div>
                                            {templateList?.length > 0 ? <div>
                                                {templateList?.map((complaint, index) => {
                                                    return (
                                                        <div key={index} className='d-flex mb-2'>
                                                            <textArea rows={3} disabled style={{ width: "80%", fontSize: "14px", padding: "4px 8px", borderRadius: "6px" }}>{complaint?.reasonForVisit}</textArea>
                                                            <div style={{ width: "20%" }}>
                                                                
                                                                <button onClick={() => {
                                                                    setTemplateInfo({ reasonForVisit: complaint?.reasonForVisit || "", chiefCompliantId: complaint?._id, doctorId: data?.doctor })
                                                                    setShowAdd(true)
                                                                }} className='bg-info btn my-0 py-0 text-end  px-2 rounded mx-2 pointer' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}>Update</button>

                                                                <button onClick={() => {
                                                                    setTemplateInfo({ chiefCompliantId: complaint?._id })
                                                                    setShowDelete(true)
                                                                }} className='bg-danger btn w text-end  px-3 rounded mx-2 pointer my-0 py-0' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}>Delete</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div> : <small>No chief complaint template found</small>}
                                        </div>
                                    </div>
                                    <div className='p-2' style={{ width: "50%" }}>
                                        <p style={{ fontSize: "14px", borderBottom: "1px solid black" }}> History Of Presnet Illness</p>
                                        <div>
                                            {presentIllnessList?.length > 0 ? <div>
                                                {presentIllnessList?.map((complaint, index) => {
                                                    return (
                                                        <div key={index} className='d-flex mb-2'>
                                                            <textArea rows={3} disabled style={{ width: "80%", fontSize: "14px", padding: "4px 8px", borderRadius: "6px" }}>{complaint?.historyOfPresetIllness}</textArea>
                                                            <div style={{ width: "20%" }}>
                                                               
                                                                <button onClick={() => {
                                                                    setTemplateInfo({ historyOfPresetIllness: complaint?.historyOfPresetIllness || "", historyOfPresetIllnessId: complaint?._id, doctorId: data?.doctor })
                                                                    setShowPresentIllness(true)
                                                                }} className='bg-info btn my-0 py-0 text-end  px-2 rounded mx-2 pointer' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}>Update</button>

                                                                <button onClick={() => {
                                                                    setTemplateInfo({ presentIllnessId: complaint?._id })
                                                                    setShowPresenetDelete(true)
                                                                }} className='bg-danger btn w text-end  px-3 rounded mx-2 pointer my-0 py-0' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}>Delete</button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div> : <small>No present illness template found</small>}
                                        </div>
                                    </div>
                                </div>
                            </table>
                        </div>
                    </div>
                </CustomModal>
            </div>
        </div>

    )
}

export default Complaint




// import React, { useEffect, useMemo, useRef, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
// import './complaints.scss'
// import { put} from '../../../../utils/fetchAPI';
// const Complaints = ({ doctorId = "1", templateId }) => {
//     let {templateDetails } = useSelector((state) => state.template);
//     const [info, setInfo] = useState({});
//     useDispatch();
//     const timeoutIdRef = useRef(null)
//     const [isSubmitting, setIsSubmitting] = useState(false)
//     const setInfoFun = (key, value) => {
//         let temp = { ...info, [key]: value };
//         setInfo(temp);
//         clearTimeout(timeoutIdRef.current);
//         timeoutIdRef.current = setTimeout(() => { (onSubmit(temp)) }, 1000);
//     };

//     const onSubmit = async (temp) => {
        
//         const payLoad = {
//             reasonForVisit: temp.reasonForVisit || "",
//             historyOfPresetIllness: temp.historyOfPresetIllness || "",
//             extraNote: temp.extraNote || ""
//         }
//         // setIsSubmitting(true)
//         await put(`doctor/template/${templateId}`, payLoad)
//             .then((res) => {
//                 if (res?.status == 200) {
//                     setIsSubmitting(false);
//                     // prescriptionRefresh()
//                 }
//             })
//             .catch((error) => {
//                 setIsSubmitting(false);
//                 console.log(error);
//             });

//     };

//     useMemo(() => {
//         setInfo(
//             {
//                 reasonForVisit: templateDetails?.reasonForVisit || "",
//                 historyOfPresetIllness: templateDetails?.historyOfPresetIllness || "",
//                 extraNote: templateDetails?.extraNote || "",
//             }
//         )
//     }, [templateDetails])


//     const handleKeyDown = (event) => {
//         if ((event.metaKey || event.ctrlKey) && event.key === 's') {

//             event.preventDefault();
//             // let values = {}
//             // values.reasonForVisit = info.reasonForVisit;
//             // values.historyOfPresetIllness = info.historyOfPresetIllness;
//             // values.patient = patientId
//             // saveFun(values)
//             onSubmit()

//         };
//     }
//     useEffect(() => {
//         if (info)
//             document.addEventListener('keydown', handleKeyDown);
//         return () => {
//             document.removeEventListener('keydown', handleKeyDown);
//         };
//     }, [info]);

//     //  const setInfoFun = (key, value, type, id) => {
//     //     let temp = { ...info };
//     //     setInfo({ ...temp, [key]: value });
//     //     clearTimeout(timeoutIdRef.current);
//     //     timeoutIdRef.current = setTimeout(() => { upDateMedicineFun(value, type, id) }, 700);
//     // };
//     return (
//         <div className='template-complaints'>
//             <div className="">
//                 <form
//                     onSubmit={(event) => onSubmit(event)}
//                     action=""
//                     id=""
//                     className="custom-form input-section position-relative"
//                     encType="multipart/form-data"
//                 >
//                     <div className="row  ">
//                         <div className="col-6 col-md-12  mb-0 pb-0 ">
//                             <label htmlFor="exampleInputEmail1" className="form-label">
//                                 Reason For Visit
//                             </label>
//                             <div className="d-flex">
//                                 {" "}
//                                 <textarea
//                                     className="form-control  w-100"
//                                     autoComplete="off"
//                                     type="text"
//                                     rows={3}
//                                     placeholder="Reason For Visit"
//                                     value={info.reasonForVisit || ""}
//                                     onChange={(e) => {
//                                         setInfoFun("reasonForVisit", e.target.value);
//                                     }}
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-6 col-md-12  mb-0 pb-0 ">
//                             <label htmlFor="exampleInputEmail1" className="form-label">
//                                 History Of Preset Illness
//                             </label>
//                             <div className="d-flex">
//                                 {" "}
//                                 <textarea
//                                     className="form-control  w-100"
//                                     autoComplete="off"
//                                     type="text"
//                                     rows={3}
//                                     placeholder="History Of Preset Illness"
//                                     value={info.historyOfPresetIllness || ""}
//                                     onChange={(e) => {
//                                         setInfoFun("historyOfPresetIllness", e.target.value);
//                                     }}
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-6 col-md-12  mb-0 pb-0 ">
//                             <label htmlFor="exampleInputEmail1" className="form-label">
//                                 Note
//                             </label>
//                             <div className="d-flex">
//                                 {" "}
//                                 <textarea
//                                     className="form-control  w-100"
//                                     autoComplete="off"
//                                     type="text"
//                                     rows={3}
//                                     placeholder="note"
//                                     value={info. extraNote || ""}
//                                     onChange={(e) => {
//                                         setInfoFun("extraNote", e.target.value);
//                                     }}
//                                 />
//                             </div>
//                         </div>
//                         {/* <div className="col-12  text-center mt-3 d-flex">
//                             <button
//                                 type="submit"
//                                 className="btn submit-button  mx-auto w-25"
//                             >
//                                 Save
//                                 {isSubmitting && (
//                                     <span
//                                         className="spinner-border spinner-border-sm"
//                                         role="status"
//                                         aria-hidden="true"
//                                     ></span>
//                                 )}
//                             </button>
//                         </div> */}
//                     </div>
//                 </form>
//             </div>
//         </div>
//     )
// }

// export default Complaints
