import React, { useEffect, useMemo, useRef, useState } from 'react'
import PrescriptionBody from './prescriptionBody';
import "./prescription-print.scss"
import PrescriptionHeader from './prescriptionHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import PrescriptionFooter from './prescriptionFooter';
import { dateFormat } from '../../../utils/common-funtions/common';
import { get } from '../../../utils/fetchAPI';
import { useSelector } from 'react-redux';

const PrescriptionPrint = ({ patientId, visitId, setShowPrint, visitDetails }) => {
    const ref = useRef()
    const [patientDetails, setPatientDetails] = useState()
    const [details, setDetails] = useState()
    const [isHeader, setIsHeader] = useState(true)
    const [isFooter, setIsFooter] = useState(true)
    const [doctorDetails, setDoctorDetails] = useState([])
    const { user, userRoles, selectedRole } = useSelector((state) => state.auth);


    useEffect(() => {
        if (patientId)
            getPatientDetails(patientId)
        if (user?.user?.type?.toLowerCase() === 'doctor' && selectedRole) {
            setDoctorDetails(selectedRole)
            setIsHeader(selectedRole?.config?.prescriptionHeaderInPrint)
            setIsFooter(selectedRole?.config?.prescriptionFooterInPrint)
        }
        else if (visitDetails?.doctor) {
            getDoctorDetails(visitDetails?.doctor)
        }
    }, [selectedRole]);



    async function getPatientDetails(patientId) {
        await get(`/patient/${patientId}`)
            .then((res) => {
                if (res?.status === 200) {
                    setPatientDetails(res.data)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function getDoctorDetails(doctorId) {
        await get(`/user/${doctorId}`)
            .then((res) => {
                if (res?.status === 200) {
                    setDoctorDetails(res.data)
                    setIsHeader(res.data.details?.config?.prescriptionHeaderInPrint)
                    setIsFooter(res.data.details?.config?.prescriptionFooterInPrint)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useMemo(() => {
        if (visitDetails)
            setDetails(visitDetails)
    }, [visitDetails])

    return (
        <>
            <div className="mt-0 pt-0 d-flex justify-content-between p-2">
                <div className='d-flex'>
                    <ReactToPrint
                        trigger={() => <p className=" mt-0 pt-0 mr-2 d-inline-block px-2 rounded" style={{ cursor: "pointer", background: "#13AC81", color: "white" }}>Print <FontAwesomeIcon className="mt-0 pt-0" icon={faPrint} /></p>}
                        content={() => ref.current}
                        onBeforePrint={() => document.title = dateFormat(patientDetails?.updatedAt?.slice(0, 10)) + "-" + patientDetails?.name + "-" + patientDetails?.patientId
                        }
                        onAfterPrint={() => document.title = "Shafa-Emr"}
                    />
                </div>
                <p onClick={() => { setShowPrint(false) }} style={{ cursor: "pointer" }} className="mt-0 pt-0"><FontAwesomeIcon className="mt-0 pt-0" icon={faCircleXmark} /></p>
            </div>
            <div style={{ height: "1700px" }}>
                <div ref={ref} className='full-prescription' style={{ height: "90vh", margin: selectedRole?.config?.prescriptionMargin || "5px px 5px 5px" }}>
                    <PrescriptionHeader isHeader={isHeader} info={patientDetails} drDetails={doctorDetails || []}></PrescriptionHeader>
                    <PrescriptionBody drDetails={doctorDetails || []} patientDetails={patientDetails} info={details}></PrescriptionBody>
                    <PrescriptionFooter isFooter={isFooter} drDetails={doctorDetails || []}></PrescriptionFooter>
                </div>
            </div>
        </>
    )
}

export default PrescriptionPrint



// import React, { useEffect, useMemo, useRef, useState } from 'react'
// import PrescriptionBody from './prescriptionBody';
// import "./prescription-print.scss"
// import PrescriptionHeader from './prescriptionHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleXmark, faPrint } from '@fortawesome/free-solid-svg-icons';
// import ReactToPrint from 'react-to-print';
// import PrescriptionFooter from './prescriptionFooter';
// import { dateFormat } from '../../../utils/common-funtions/common';
// import { get } from '../../../utils/fetchAPI';
// import { useSelector } from 'react-redux';

// const PrescriptionPrint = ({ patientId, visitId, setShowPrint, visitDetails }) => {
//     const ref = useRef()
//     const [patientDetails, setPatientDetails] = useState()
//     const [details, setDetails] = useState()
//     const [isHeader, setIsHeader] = useState(true)
//     const [isFooter, setIsFooter] = useState(true)
//     const [doctorDetails, setDoctorDetails] = useState([])
//     const { user, userRoles, selectedRole } = useSelector((state) => state.auth);
//     const reactToPrintRef = useRef();

//     useEffect(() => {
//         if (patientId)
//             getPatientDetails(patientId)
//         if (user?.user?.type?.toLowerCase() === 'doctor' && selectedRole) {
//             setDoctorDetails(selectedRole)
//             setIsHeader(selectedRole?.config?.prescriptionHeaderInPrint)
//             setIsFooter(selectedRole?.config?.prescriptionFooterInPrint)
//         }
//         else if (visitDetails?.doctor) {
//             getDoctorDetails(visitDetails?.doctor)
//         }
//     }, [selectedRole]);



//     async function getPatientDetails(patientId) {
//         await get(`/patient/${patientId}`)
//             .then((res) => {
//                 if (res?.status === 200) {
//                     setPatientDetails(res.data)
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     }

//     async function getDoctorDetails(doctorId) {
//         await get(`/user/${doctorId}`)
//             .then((res) => {
//                 if (res?.status === 200) {
//                     setDoctorDetails(res.data)
//                     setIsHeader(res.data.details?.config?.prescriptionHeaderInPrint)
//                     setIsFooter(res.data.details?.config?.prescriptionFooterInPrint)
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     }



//     const handleKeyDown = (event) => {
//         if (event.ctrlKey && event.key === "p") {
//             event.preventDefault(); // Prevent default browser print behavior
//             if (reactToPrintRef?.current) {
//                 reactToPrintRef?.current?.handlePrint(); // Trigger print programmatically
//             }
//         }
//         if (event.key === "Escape") {
//             setShowPrint(false)
//          }
//     };

//     useEffect(() => {
//         document.addEventListener("keydown", handleKeyDown);
//         return () => {
//             document.removeEventListener("keydown", handleKeyDown);
//         };
//     }, []);

//     useMemo(() => {
//         if (visitDetails)
//             setDetails(visitDetails)
//     }, [visitDetails])

//     return (
//         <>
//             <div className="mt-0 pt-0 d-flex justify-content-between p-2">
//                 <div className='d-flex' id="react-to-print-1">
//                     <ReactToPrint
//                         ref={reactToPrintRef}
//                         trigger={() => <p className=" mt-0 pt-0 mr-2 d-inline-block px-2 rounded" style={{ cursor: "pointer", background: "#13AC81", color: "white" }}>Print {"(ctrl+p)"}  <FontAwesomeIcon className="mt-0 pt-0" icon={faPrint} /></p>}
//                         content={() => ref.current}
//                         onBeforePrint={() => document.title = dateFormat(patientDetails?.updatedAt?.slice(0, 10)) + "-" + patientDetails?.name + "-" + patientDetails?.patientId
//                         }
//                         onAfterPrint={() => document.title = "Shafa-Emr"}
//                     />
//                 </div>
//                 <p onClick={() => { setShowPrint(false) }} style={{ cursor: "pointer" }} className="mt-0 pt-0"><FontAwesomeIcon className="mt-0 pt-0" icon={faCircleXmark} /></p>
//             </div>
//             <div style={{ height: "1700px" }}>
//                 <div ref={ref} className='full-prescription' style={{ height: "90vh", margin: selectedRole?.config?.prescriptionMargin || "5px px 5px 5px" }}>
//                     <PrescriptionHeader isHeader={isHeader} info={patientDetails} drDetails={doctorDetails || []}></PrescriptionHeader>
//                     <PrescriptionBody drDetails={doctorDetails || []} patientDetails={patientDetails} info={details}></PrescriptionBody>
//                     <PrescriptionFooter isFooter={isFooter} drDetails={doctorDetails || []}></PrescriptionFooter>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default PrescriptionPrint
