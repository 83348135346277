import React, { useEffect, useRef, useState } from 'react'
import { Select } from "antd";
import "./creatable-search-select.scss"
const CreatableSearchSelectAntD = ({ customClass = "input-color-border", isDisabled = false, placeHolder = "search", isCreatable = true, options, setOptionFun, handleSubmit, handleCreate, isSearching }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectionValue, setSelectionValue] = useState(null);
  const [selectionOptions, setSelectionOptions] = useState(options);
  const [show, setShow] = useState(false)
  const ref = useRef();

  useEffect(() => {
    setSelectionOptions(options); // Update options dynamically
  }, [options]);

  const onSearch = (inputValue) => {
    setSearchQuery(inputValue);
    const exists = selectionOptions.some((opt) => opt.label === inputValue);
    if (!exists && isCreatable && inputValue.trim() !== "") {
      setSelectionOptions([
        ...options, // Existing options
        { value: JSON.stringify({ _id: "new", value: inputValue }), label: inputValue },
      ]);
    }
  };


  const onSelectChange = (newValue) => {
    let value = JSON.parse(newValue)
    if (value?._id == "new") {
      handleCreate(value?.value)
    }
    else {
      console.log("check 1 n32", value)
      handleSubmit(value);
    }
  };

  return (
    // <div className='creatable-search-select' ref={ref} onClick={() => { setShow(true) }}>
    <div className='' ref={ref} onClick={() => { setShow(true) }}>
      <Select
        showSearch
        mode={undefined} // Removes multiple selection, keeping it single-select
        size="large"
        placeholder={placeHolder}
        style={{ width: "100%" }}
        disabled={isDisabled}
        value={selectionValue}
        onChange={onSelectChange}
        onSearch={onSearch}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        options={selectionOptions}
      />
    </div>
  )
}

export default CreatableSearchSelectAntD
