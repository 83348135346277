import React, { useMemo, useRef, useState } from 'react'
import './physical-exam.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faClose, faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import TemplateAdd from './template-add';
import { put } from '../../../utils/fetchAPI';
import CustomModal from '../../common/modal/modal';

const TemplateList = ({ handlePatientPhysicalExam, templateList = [], setTemplateShow, doctorPhysicalExamList, visitDetails, setDoctorPhysicalExamList, setSelectedPhysicalExam, getDoctorPhysicalExamList }) => {
    let [selectedTemplates, setSelectedTemplates] = useState([])
    const [newTemplate, setNewTemplate] = useState(false)
    const timeoutIdRef = useRef()
    const [editModal, setEditModal] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState({})
    const [templateName, setTemplateName] = useState("")
    // console.log("templateList", templateList, templateList?.templates
    // )
    const manageSelectedTemplates = (checkbox, template, index) => {
        let list = [...selectedTemplates]
        let checkIndex = list?.some((obj, index) => { if (obj?.name === template?.name) return index });
        if (checkbox == true)
            list = [...list, template]
        else if (checkbox == false) {
            let checkBoxComponent = document.getElementById(`${template?.name}-${template?._id}`)
            if (checkBoxComponent) {
                list.splice(checkIndex, 1);
                checkBoxComponent.checked = false
            }
        }
        setSelectedTemplates(list)
    }

    const updateTemplates = async (template, value) => {

        let checkSelectedIndex = selectedTemplates?.findIndex(obj => obj?._id === template?._id);
        if (checkSelectedIndex != -1) {
            let updatedList = [...selectedTemplates]
            updatedList[checkSelectedIndex]['desc'] = value
            setSelectedTemplates(updatedList)
        }
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(async () => {
            await put(`/physical-exam/update-template/${templateList?._id}`, {
                "templates.$.desc": value,
                "_id": template?._id
            })
                .then((res) => {
                    if (res?.status === 200) {
                        let checkIndex = doctorPhysicalExamList?.findIndex(obj => obj?._id === templateList?._id);
                        if (checkIndex != -1) {
                            let updatedList = [
                                ...doctorPhysicalExamList.slice(0, checkIndex), // elements before the found index
                                res?.data, // your new element
                                ...doctorPhysicalExamList.slice(checkIndex + 1), // elements after the found index
                            ];
                            setDoctorPhysicalExamList(updatedList)
                        }
                        setSelectedPhysicalExam(res?.data)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {

                }
                );
        }, 1000);
    }

    const updateTemplateName = async (value) => {
        if (selectedTemplate) {
            await put(`/physical-exam/update-template/${templateList?._id}`, {
                "templates.$.name": value,
                "_id": selectedTemplate?._id
            })
                .then((res) => {
                    if (res?.status === 200) {
                        let checkIndex = doctorPhysicalExamList?.findIndex(obj => obj?._id === selectedTemplate?._id);
                        if (checkIndex != -1) {
                            let updatedList = [
                                ...doctorPhysicalExamList.slice(0, checkIndex), // elements before the found index
                                res?.data, // your new element
                                ...doctorPhysicalExamList.slice(checkIndex + 1), // elements after the found index
                            ];
                            setDoctorPhysicalExamList(updatedList)
                        }
                        setSelectedPhysicalExam(res?.data)
                        setSelectedTemplates([])
                        setTemplateName("")
                        getDoctorPhysicalExamList()
                        setEditModal(false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {

                }
                );
        }
    }

    const deleteTemplate = async (template) => {
        // console.log("template", template?._id)
        await put(`/physical-exam/remove-template/${templateList?._id}`, { templates: { _id: template?._id } })
            .then((res) => {
                if (res?.status === 200) {
                    let checkIndex = doctorPhysicalExamList?.findIndex(obj => obj?._id === templateList?._id);
                    if (checkIndex) {
                        let updatedList = [...doctorPhysicalExamList.slice(0, checkIndex), res?.data, ...doctorPhysicalExamList.slice(checkIndex + 1)];
                        setDoctorPhysicalExamList(updatedList)
                    }
                    setSelectedPhysicalExam(res?.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {

            }
            );
    }
    return (
        <div className='template-list-container py-0 my-0'>
            <div className='d-flex justify-content-between title px-2'>
                <p style={{ cursor: "pointer" }} onClick={() => setTemplateShow(false)} className='title py-0 my-0 my-xxl-0'>{templateList?.displayName}</p>
                <p style={{ cursor: "pointer" }} onClick={() => setTemplateShow(false)} className='title py-0 my-0 my-xxl-0'> <FontAwesomeIcon className="mx-1 text-danger my-0" icon={faClose} /></p>
            </div>
            <div className='d-flex'>
                <div className='list my-0 py-0 ' style={{ borderRight: '1px solid gray', width: "30%", height: "60vh", overflow: "scroll" }}>
                    <p className='py-0 my-0 mb-1' style={{ borderBottom: "1px solid gray", fontSize: "14px", textAlign: "center" }}>Template list <FontAwesomeIcon onClick={() => setNewTemplate(!newTemplate)} style={{ backgroundColor: "#13AC81", color: "white", borderRadius: "2px", cursor: "pointer" }} className="mx-1 my-0 px-1" icon={faPlus} /></p>
                    {templateList?.templates?.length > 0 && templateList?.templates?.map((list, index) => {
                        return (
                            <div key={index} className='individual-list d-flex justify-content-between mb-1'>
                                <span>
                                    <input className='mt-1' disabled={newTemplate} onChange={(e) => { manageSelectedTemplates(e.target.checked, list, index) }} type='checkBox' id={list?.name + "-" + list?._id}></input>
                                    <label htmlFor={list?.name + "-" + list?._id} className='p-0 m-0 px-2'>{list?.name}</label>
                                </span>
                                <div>
                                    <span>
                                        <FontAwesomeIcon size='xs' onClick={() => {
                                            setSelectedTemplate(list)
                                            setTemplateName(list?.name || "")
                                            setEditModal(true)
                                        }} style={{ cursor: "pointer" }} className="mx-1 my-0 px-1 text-success" icon={faPenToSquare} />
                                    </span>
                                    <span>
                                        <FontAwesomeIcon size='xs' onClick={() => deleteTemplate(list)} style={{ cursor: "pointer" }} className="mx-1 my-0 px-1 text-danger" icon={faTrash} />
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                    {templateList?.templates?.length == 0 && <small className='p-1 mx-1'>No templates available please add</small>}
                </div>
                <div className='list my-0 py-0 ' style={{ height: "55vh", position: "relative", width: "75%" }}>
                    <div className='border' style={{ height: "55vh", overflowY: "scroll" }}>
                        {!newTemplate && selectedTemplates?.length > 0 && selectedTemplates?.map((template, index) => {
                            return (
                                <div className='m-1' key={index}>
                                    <p className='title py-0 my-0' style={{ fontSize: '13px' }}>{template?.name}  <FontAwesomeIcon onClick={(e) => { manageSelectedTemplates(false, template, index) }} className="mx-1 text-danger my-0" icon={faClose} style={{ cursor: "pointer" }} /></p>
                                    <textarea
                                        autoComplete="off"
                                        id={`template-update-${template?._id}`}
                                        className={`w-100`}
                                        // data-multi-title={"check"}
                                        placeholder={"No exam"}
                                        defaultValue={template?.desc}
                                        onChange={(e) => updateTemplates(template, e.target.value)}
                                        rows={2}
                                    ></textarea>
                                </div>)
                        })}
                        {!newTemplate && selectedTemplates?.length == 0 && <p style={{ fontSize: "13px", textAlign: "center" }}>Select templates from list</p>}
                        {
                            newTemplate && <TemplateAdd templateList={templateList} setSelectedPhysicalExam={setSelectedPhysicalExam} setDoctorPhysicalExamList={setDoctorPhysicalExamList} visitDetails={visitDetails} doctorPhysicalExamList={doctorPhysicalExamList} setNewTemplate={setNewTemplate} setSelectedTemplates={setSelectedTemplates}></TemplateAdd>
                        }
                    </div>

                    {selectedTemplates?.length > 0 && <div className='text-center d-flex justify-content-center'>
                        <p onClick={() => { handlePatientPhysicalExam('template', selectedTemplates, templateList) }} className='text-center w-25' style={{ cursor: "pointer", backgroundColor: "#13AC81", color: "white" }}>Save to patient</p>
                    </div>}

                </div>

            </div>

            <CustomModal
                size="sm"
                show={editModal}
                isHeaderHide={true}
                setShow={setEditModal}
                autoClose={false}
            >
                <div>
                    <div className='p-0 m-0 form-title d-flex justify-content-between px-1'>
                        <p style={{ fontSize: "13px", fontWeight: "700" }} className='p-0 m-0 px-1'>Update name</p>
                        <p style={{ cursor: "pointer" }} onClick={(e) => { setEditModal(false) }} className='p-0 m-0 pl-5 pr-2'> <FontAwesomeIcon className="mx-1 my-0 text-danger" icon={faClose} style={{ cursor: "pointer", color: "#13AC81" }} /> </p>
                    </div>
                    {/* <label  className='p-0 m-0 px-2'>Name</label> */}
                    <div className='p-2 w-100'>
                        <input style={{ fontSize: "13px", width: "70%" }} className='mt-1 ' value={templateName || ""} onChange={(e) => { setTemplateName(e.target.value) }} type='text' ></input>
                        <button onClick={() => updateTemplateName(templateName)} className='btn py-0 w-25 ml-2' style={{ backgroundColor: "#12AB81", color: "white", width: "20%" }}> Update</button>
                    </div>
                </div>
            </CustomModal>
        </div >
    )
}

export default TemplateList
