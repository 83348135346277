import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { del, get, post, put } from '../../../utils/fetchAPI';
import { showSuccessToast } from '../../../utils/toaster-alert';
import CustomModal from '../../common/modal/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faSquarePlus, faTrash } from '@fortawesome/free-solid-svg-icons';

export const ReferraListCreate = () => {
    const [referralList, setReferralList] = useState([])
    const { user } = useSelector((state) => state.auth);
    const [updatedData, setUpdatedData] = useState({})
    const [createData, setCreateData] = useState({})
    const [show, setShow] = useState(false)

    useEffect(() => {
        getRefferalList(user?.user?._id)
    }, [])

    const getRefferalList = async (doctorId = user?.user?._id, search = "") => {
        await get(`/doctor/referral/${doctorId}`)
            .then((res) => {
                console.log("check dara", res)
                if (res?.status == 200) {
                    setReferralList(res?.data)
                    setUpdatedData({})
                    setCreateData({})
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const addReferral = async (doctorId, data) => {
        await post(`/doctor/referral/create`, {
            "doctorId": doctorId,
            "details": data?.details
        })
            .then((res) => {
                if (res?.status == 200) {
                    getRefferalList()
                    setCreateData({})
                    showSuccessToast("Created successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    const updateReferral = async (doctorId, updatedReferral) => {
        await put(`/doctor/referral/update`, {
            "doctorId": doctorId,
            "referralId": updatedReferral?.id,
            "details": updatedReferral?.details
        })
            .then((res) => {
                console.log("check dara", res)
                if (res?.status == 200) {
                    getRefferalList()
                    showSuccessToast("Updated successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });
    }

    const deleteReferral = async (updatedReferral) => {
        await del(`/doctor/referral/delete/${updatedReferral?.id}`)
            .then((res) => {
                console.log("check dara", res)
                if (res?.status == 200) {
                    setShow(false)
                    getRefferalList()
                    showSuccessToast("deleted successfully")
                }
            })
            .catch((error) => {
                // setIsSubmitting(false);
            });;
    }

    return (
        <div className='mx-5' style={{ border: ".4px solid black", borderRadius: "6px" }}>
            <p className='px-2' style={{ fontSize: "14px", fontWeight: "600", backgroundColor: "#15AA81", color: "white" }}>Referrals</p>
            <div className='d-flex mb-2 p-2 border-bottom pb-4'>
                <textarea onChange={(e) => {
                    setCreateData({
                        details: e.target.value
                    })
                }}
                    value={createData?.details || ""}
                    rows={2}
                    placeholder='create referral'
                    style={{ width: "80%", fontSize: "13px", padding: "4px 8px", borderRadius: "6px" }}>
                </textarea>
                <div style={{ width: "20%" }}>
                    <button onClick={() => {
                        addReferral(user?.user?._id, createData)
                    }} className=' btn my-0 py-0 text-end  px-2 rounded mx-2 pointer' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center",backgroundColor:"#17A981" }} disabled={!createData?.details}><FontAwesomeIcon  icon={faSquarePlus} /></button>
                </div> 
            </div>
            {referralList?.length > 0 ? <div className='p-2'>
                {referralList?.map((data, index) => {
                    return (
                        <div key={index} className='d-flex mb-2'>
                            <textarea onChange={(e) => {
                                setUpdatedData({
                                    id: data?._id,
                                    details: e.target.value
                                })
                            }}
                                value={data?._id == updatedData?.id ? updatedData?.details : data?.details}
                                rows={2}
                                style={{ width: "80%", fontSize: "13px", padding: "4px 8px", borderRadius: "6px" }}>
                            </textarea>
                            <div className='d-flex' style={{ width: "20%" }}>
                                <div>
                                    <button onClick={() => {
                                        updateReferral(user?.user?._id, updatedData)
                                    }} className='bg-info btn my-0 py-0 text-end  px-2 rounded mx-2 pointer' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }} disabled={updatedData?.id == data?._id ? false : true}><FontAwesomeIcon icon={faFloppyDisk} /></button>

                                    <button onClick={() => {
                                        setUpdatedData({
                                            id: data?._id,
                                            details: data?.details
                                        })
                                        setShow(true)
                                    }} className='bg-danger btn w text-end  px-3 rounded mx-2 pointer my-0 py-0' style={{ fontSize: "13px", cursor: "pointer", width: "70px", textAlign: "center" }}><FontAwesomeIcon icon={faTrash} /></button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div> : <small>No template found</small>}

            <CustomModal
                size="sm"
                show={show}
                isHeaderHide={true}
                setShow={setShow}
            >
                <div className='mt-0  create-template px-2 py-2'>
                    <p>Are ypu sure?</p>
                    <div className='text-center mt-2'>
                        <button onClick={() => {
                            deleteReferral(updatedData)
                        }} className='btn py-0 mr-1 bg-danger'>delete
                        </button>
                        <button onClick={() => setShow(false)} className='btn py-0'>Cancel</button>
                    </div>
                </div>
            </CustomModal>
        </div>
    )
}
