import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import "./appointment-create-form.scss";
import { appointmentCreate } from "../../service/api/appointment-api";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getLocations } from "../../store/slices/locationSlice";
import SelectWithLocalSearch from "../common/updated-input/select-with-local-search/select-with-local-search";
import { convertAgeToDob, convertDobToAge } from "../../utils/common-funtions/common";
import { Toast } from "../../utils/SwalUti";
import { useMatch } from "react-router-dom";

const AppointmentCreateForm = ({
    selectedPatientInfo, isNewUser, setSinglePatientInfo, setIsNewUser, refresh, setShow,
}) => {
    const [info, setInfo] = useState({ appointmentDate: new Date().toJSON(), gender: "Male", type: "new" });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [date] = useState(new Date().toJSON());
    const { user, userRoles, selectedRole } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [selectedLocation, setSelectedLocation] = useState({})
    const [age, setAge] = useState({
        age: 0,
        ageUnit: "year(s)"
    })

    let { isLoading, allLocations, noLocationFound, locationOptions } = useSelector(
        (state) => state.location
    );
    const maritalStatusOptions = [
        {
            id: 1,
            name: "Married",
            value: "married"
        },
        {
            id: 2,
            name: "Unmarried",
            value: "unmarried"
        },
    ]

    useEffect(() => {
        if (selectedPatientInfo?.name) {
            setInfo({ appointmentDate: date, ...selectedPatientInfo });
            let currentAge = convertDobToAge(selectedPatientInfo?.dob, age?.ageUnit) || ""
            let splitCurrentAge = currentAge?.split(" ")
            setAge({
                age: splitCurrentAge[0], ageUnit: splitCurrentAge[1]
            })
        } else {
            setInfo({ appointmentDate: new Date().toJSON(), gender: "Male", type: "new" });
        }
    }, [selectedPatientInfo?.name]);
  
    const onSubmit = async (values, { resetForm }) => {
        let { age, ...payload } = info
        values = payload;
        if (selectedRole?.role.code.toLowerCase() === "doctor") {
            values.doctorId = user?.user?._id;
            values.officeLocationId = selectedRole?.location?._id
        }
        console.log("payload",values)

        await appointmentCreate(values)
            .then((res) => {
                if (res.status === 200) {
                    resetForm();
                    setIsNewUser(false);
                    setIsSubmitting(false);
                    refresh();
                    setShow(false);
                    Toast.fire({
                        icon: '',
                        title: "New appointment created successfully"
                    })
                }
            })
            .catch((error) => {
                setIsSubmitting(false);
            });
    };
    const setInfoFun = (key, value) => {
        let temp = { ...info };
        setInfo({ ...temp, [key]: value });
    };


    const setDobAgeInfoFun = (key, value) => {
        let temp = { ...info };
        if (key == "dob") {
            let currentAge = convertDobToAge(value, age?.ageUnit)
            setInfo({ ...temp, dob: value });
            let splitCurrentAge = currentAge?.split(" ")
            setAge({
                age: splitCurrentAge[0], ageUnit: splitCurrentAge[1]
            })
        } else if (key == "ageUnit") {
            setInfo({ ...temp, dob: convertAgeToDob(age?.age, value), age: age?.age + " " + value });
            setAge({ ...age, ageUnit: value })
        } else {
            setInfo({ ...temp, dob: convertAgeToDob(value, age?.ageUnit), age: value + " " + age?.ageUnit });
            setAge({ ...age, age: value })
        }
    };


    return (
        <Formik
            initialValues={{
                name: "",
                phone: "",
                email: "",
                gender: "",
                dob: "",
                bloodGroup: "",
                maritalStatus: ""
            }}
            onSubmit={onSubmit}
        >
            {(formik) => (
                <div className="appointment-create-form-section pb-0">
                    <Form
                        action=""
                        id=""
                        className="custom-form input-section position-relative"
                        encType="multipart/form-data"
                    >
                        <div className="row mt-0 pt-0">
                            <p className="mx-2 mt-0 pt-0 ml-2 border-bottom col-12"
                                style={{ fontSize: "14px", fontWeight: "600" }}>Create new appointment & Register
                                patient</p>
                            <div className="col-12 my-0 mx-1 pb-0   d-flex" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label py-0 my-0"
                                    style={{ width: '40%' }}>
                                    Name<span className="text-danger">*</span> :
                                </label>
                                <input
                                    required
                                    className="form-control input py-0 my-0"
                                    autoComplete="off"
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={info?.name || ""}
                                    placeholder="Enter Name"
                                    //   defaultValue={info?.name}
                                    onChange={(e) => {
                                        setInfoFun("name", e.target.value);
                                    }}
                                />
                                <br />
                            </div>
                            <div className="col-12 my-0 py-0 mx-1 mb-1 d-flex mb-1">
                                <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                                    Patient Id :
                                </label>
                                <input
                                    className="form-control input"
                                    autoComplete="off"
                                    type="string"
                                    id="age"
                                    name="age"
                                    disabled
                                    placeholder="Patient id"
                                    value={info?.patientId || ""}
                                    onChange={(e) => {
                                        setInfoFun("patientId", e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-12  d-flex my-0 py-0 mx-1 mb-1" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '40%' }}>
                                    Phone Number:
                                </label>
                                <input
                                    className="form-control input"
                                    autoComplete="off"
                                    pattern="(^(01){1}[3-9]{1}\d{8})$"
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    placeholder="Enter Phone Number"
                                    value={info.phone || ""}
                                    onChange={(e) => {
                                        setInfoFun("phone", e.target.value);
                                    }}
                                />
                                <br />
                                {/* <ErrorMessage name='firstName'></ErrorMessage> */}
                            </div>

                            <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                                    Email:
                                </label>
                                <input
                                    className="form-control input"
                                    autoComplete="off"
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter Email"
                                    value={info?.email || ""}
                                    onChange={(e) => {
                                        setInfoFun("email", e.target.value);
                                    }}
                                />
                                <br />
                                {/* <ErrorMessage name='firstName'></ErrorMessage> */}
                            </div>

                            <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                                    Age<span className="text-danger">*</span>:
                                </label>
                                <input
                                    style={{ width: '78%' }}
                                    required
                                    className="form-control input"
                                    autoComplete="off"
                                    type="number"
                                    id="age"
                                    name="age"
                                    placeholder="Enter age"
                                    value={age?.age || ""}
                                    onChange={(e) => {
                                        setDobAgeInfoFun("age", e.target.value);
                                    }}
                                />
                                <select
                                    onChange={(e) => {
                                        setDobAgeInfoFun("ageUnit", e.target.value);
                                    }}
                                    value={age?.ageUnit || ""}
                                >
                                    <option value={'year(s)'}>year(s)</option>
                                    <option value={'month(s)'}>month(s)</option>
                                    <option value={'day(s)'}>day(s)</option>
                                </select>{" "}
                                <br />
                            </div>

                            <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                                    Date of birth <span className="text-danger">*</span> : <br></br> <small
                                        className="text-secondary">(dd/mm/yyyy)</small>
                                </label>
                                <input
                                    required
                                    className="form-control input"
                                    autoComplete="off"
                                    type="date"
                                    id="dob"
                                    name="dob"
                                    placeholder="Enter appointmentDate"
                                    value={info?.dob?.slice(0, 10) || ""}
                                    onChange={(e) => {
                                        setDobAgeInfoFun("dob", e.target.value);
                                    }}
                                />
                                <br />
                            </div>

                            <div className="col-12 my-2 mx-1 pb-0   d-flex" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label py-0 my-0"
                                    style={{ width: '40%' }}>
                                    Referred by :
                                </label>
                                <textarea
                                    rows={2}
                                    className="form-control input py-0 my-0 px-1"
                                    autoComplete="off"
                                    type="text"
                                    style={{ fontSize: "13px" }}
                                    id="name"
                                    name="name"
                                    value={info?.referredBy || ""}
                                    placeholder="Referred by"
                                    //   defaultValue={info?.name}
                                    onChange={(e) => {
                                        setInfoFun("referredBy", e.target.value);
                                    }}
                                />
                                <br />
                            </div>

                            <div className="col-12 my-2 mx-1 pb-0   d-flex" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label py-0 my-0"
                                    style={{ width: '40%' }}>
                                    Address :
                                </label>
                                <textarea
                                    rows={2}
                                    className="form-control input py-0 my-0 px-1"
                                    autoComplete="off"
                                    type="text"
                                    style={{ fontSize: "13px" }}
                                    id="name"
                                    name="name"
                                    value={info?.address || ""}
                                    placeholder="Enter address"
                                    //   defaultValue={info?.name}
                                    onChange={(e) => {
                                        setInfoFun("address", e.target.value);
                                    }}
                                />
                                <br />
                            </div>

                            <div className="col-12 individual-input-section d-flex mx-1 mb-1" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                                    Blood Group
                                </label>
                                <select
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        setInfoFun("bloodGroup", e.target.value);
                                    }}
                                    value={info?.bloodGroup || ""}
                                >
                                    <option value={""}>Select</option>
                                    <option value={'A+'}>A+</option>
                                    <option value={'A-'}>A-</option>
                                    <option value={'B+'}>B+</option>
                                    <option value={'B-'}>B-</option>
                                    <option value={'AB+'}>AB+</option>
                                    <option value={'AB-'}>AB-</option>
                                    <option value={'O+'}>O+</option>
                                    <option value={'O-'}>O-</option>
                                </select>{" "}
                            </div>
                            <div className="col-12 mb-1" style={{ height: '30px' }}>
                                <div className="mx-1 d-flex">
                                    <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '29%' }}>
                                        Gender<span className="text-danger">*</span>:
                                    </label>
                                    <div
                                        role="group"
                                        aria-labelledby="my-radio-group"
                                        style={{ fontSize: "20px" }}
                                        className="d-flex px-0 mx-0"
                                    >
                                        <label className=" d-flex mr-3">
                                            <input
                                                checked={
                                                    info?.gender?.toLowerCase() === "male"
                                                }
                                                type="radio"
                                                name="gender"
                                                value="Male"
                                                required={info?.gender?.length <= 0}
                                                onClick={() => {
                                                    setInfoFun("gender", "Male");
                                                }}
                                            />
                                            <span className="ml-2 mt-1">Male</span>
                                        </label>
                                        <label className="m-0 p-0 d-flex">
                                            <input
                                                checked={
                                                    info?.gender?.toLowerCase() === "female"
                                                }
                                                required={info?.gender?.length <= 0}
                                                type="radio"
                                                name="gender"
                                                value="Female"
                                                onClick={() => {
                                                    setInfoFun("gender", "Female");
                                                }}
                                            />
                                            <span className="ml-2 mt-1">Female</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mb-0 pb-0 mx-1 d-flex mb-2" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                                    Marital Status:
                                </label>
                                <div className="mt-0 pt-0 w-100 " key={3}>
                                    <SelectWithLocalSearch currentValue={info?.maritalStatus} name="maritalStatus"
                                        handleFormValues={(status) => {
                                            setInfoFun("maritalStatus", status?.value);
                                        }} isCreatable={false} options={maritalStatusOptions}
                                        placeHolder='Select marital status'></SelectWithLocalSearch>
                                </div>
                                <br />
                            </div>
                            {user?.user?.type?.toLowerCase() !== "doctor" &&
                                <div className="col-12 mb-0 pb-0 d-flex mx-1 mb-2" style={{ height: '30px' }}>
                                    <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                                        Doctor<span className="text-danger">*</span>:
                                    </label>
                                    <div className="w-100" key={2}>
                                        <SelectWithLocalSearch required={true} currentValue={selectedLocation?.doctors?.length > 0 ? selectedLocation?.doctors[0]?.name : ""}
                                            isDisabled={!selectedLocation?.doctors?.length}
                                            name="doctor" handleFormValues={(doctor) => {
                                                setInfoFun("doctorId", doctor?._id);
                                            }} isCreatable={false} options={selectedLocation?.doctors || []}
                                            placeHolder='Select doctor'></SelectWithLocalSearch>
                                    </div>
                                    <br />
                                </div>}
                            <div className="col-12 mx-1 d-flex mb-2" style={{ height: '30px' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label " style={{ width: '40%' }}>
                                    Appointment Date<span className="text-danger">*</span>:
                                </label>
                                <input
                                    required
                                    className="form-control input"
                                    autoComplete="off"
                                    type="date"
                                    id="appointmentDate"
                                    name="appointmentDate"
                                    placeholder="Enter appointmentDate"
                                    value={info?.appointmentDate?.slice(0, 10)}
                                    onChange={(e) => {
                                        setInfoFun("appointmentDate", e.target.value);
                                    }}
                                />
                                <br />
                            </div>
                            <div className="col-12 " style={{ height: '30px' }}>
                                <div className="d-flex mx-1">
                                    <label htmlFor="exampleInputEmail1" className="form-label" style={{ width: '26%' }}>
                                        Appointment type<span className="text-danger">*</span>:
                                    </label>
                                    <div
                                        role="group"
                                        aria-labelledby="my-radio-group"
                                        style={{ fontSize: "20px" }}
                                        className="d-flex"
                                    >
                                        <label className="mx-3 m-0 p-0 d-flex">
                                            <input
                                                type="radio"
                                                name="appointmentType"
                                                value="new"
                                                checked={info?.type == "new"}
                                                required={info?.type?.length <= 0}
                                                onClick={() => {
                                                    setInfoFun("type", "new");
                                                }}
                                            />
                                            <span className="ml-2 mt-1">New</span>
                                        </label>
                                        <label className="m-0 p-0 ml-3 d-flex">
                                            <input
                                                type="radio"
                                                name="appointmentType"
                                                value="old"
                                                required={info?.type?.length <= 0}
                                                onClick={() => {
                                                    setInfoFun("type", "old");
                                                }}
                                            />
                                            <span className="ml-2 mt-1">Old</span>
                                        </label>
                                        <label className="m-0 p-0 ml-4 d-flex">
                                            <input
                                                type="radio"
                                                name="appointmentType"
                                                value="followUp"
                                                required={info?.type?.length <= 0}
                                                onClick={() => {
                                                    setInfoFun("type", "followUp");
                                                }}
                                            />
                                            <span className="ml-2 mt-1">Follow Up</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12  text-center mt-3 d-flex">
                                <button
                                    type="submit"
                                    className="btn submit-button submit-button-position mx-auto w-25"
                                    onClick={() => {
                                    }}
                                >
                                    Create
                                    {isSubmitting && (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
};

export default AppointmentCreateForm;
