import React, { useEffect, useMemo, useRef, useState } from 'react'
import "./medicine.scss"
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllMedicineList, getDoctorMedicineList } from '../../../../store/slices/prescriptionSlice';
import { prescriptionAddSubDocument, prescriptionRemoveSubDocument, prescriptionUpdateSubDocument } from '../../../../service/api/prescription';
import CustomModal from '../../../common/modal/modal';
import {
    faArrowAltCircleDown,
    faArrowAltCircleUp,
    faArrowCircleDown,
    faArrowDown,
    faArrowUp,
    faEdit,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import MedicineSigForm from './medicine-sig-form/medicine-sig-form';
import UniversalLoaderForComponent from '../../../loader/universalLoaderForComponent';
import { templateAddSubDocument, templateRemoveSubDocument, templateUpdateSubDocument } from '../../../../service/api/template';
import { put } from '../../../../utils/fetchAPI';
const Medicine = ({ visitDetails, updateState, templateDetails, templateId }) => {

    let { isMedicineListLoading, medicineList, doctorMedicineList,
        isDoctorMedicineListLoading, isLoading, isNoMedicine } = useSelector(
            (state) => state.prescription
        );
    let { user } = useSelector((state) => state.auth);
    const [show, setShow] = useState(false)
    const [sig, setSig] = useState("")
    const [selectedMedicine, setSelectedMedicine] = useState({})
    const [docMedicineShow, setDocMedicineShow] = useState(true)
    const [searchType, setSearchType] = useState("name")
    const [info, setInfo] = useState({})
    const [isDoubleClick, setIsDoubleClick] = useState(false)
    const [isDocMedicineUp, setIsDocMedicineUp] = useState(false)
    const [searchString, setSearchString] = useState('')
    const timeoutIdRef = useRef(null)
    const [prescribedMedicines, setPrescribedMedicines] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        const payload = { doctorId: user?.user?._id, search: "" }
        dispatch(getDoctorMedicineList(payload))
    }, [])

    const medicineSearch = (value, event) => {
        event.preventDefault()
        if (value?.length > 0 && /^\s*$/.test(value) == false) {
            let payLoad = {
                key: searchType, search: value.trim(), manufacturerName: "", medicinePriority: false
            }
            if (user?.user?.details?.sponsor == "true") {
                payLoad.manufacturerName = user?.user?.organization?.manufacturerName
                payLoad.medicinePriority = user?.user?.organization?.medicinePriority
            }

            dispatch(getAllMedicineList(payLoad))
            setDocMedicineShow(false)
        }
        else setDocMedicineShow(true)
    }


    const medicineSearchWithTimeOut = (value, event) => {
        setSearchString(value)
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { medicineSearch(value, event) }, 500);
    };

    const checkAndAddMedicine = (medicine) => {
        if (medicine?.sig?.length) {
            medicineAddToPrescription(medicine)
        }
        else {
            setSelectedMedicine(medicine)
            setShow(true)
        }
    }

    const addNewMedicine = () => {
        setSelectedMedicine({})
        setShow(true)
    }

    const medicineAddToPrescription = async (medicine) => {
        setIsSubmitting(true)
        if (templateId) {
            let payLoad = {
                // doctorId: medicine?.doctorId,
                power: medicine?.power || "",
                type: medicine?.type || "",
                generic: medicine?.generic || "",
                manufacturer: medicine?.manufacturer || "",
                medicine: medicine?.medicineId || null,
                sig: medicine?.sig || "",
                name: medicine?.name || "",
                route: medicine?.route || "",
                take: medicine?.take || "",
                frequency: medicine?.frequency || "",
                repeat: medicine?.repeat || "",
                duration: medicine?.duration || "",
                doctorMedicineId: medicine?.doctorMedicineId || "",
                addToDoctorMedicine: medicine?.addToDoctorMedicine || false
            }
            await templateAddSubDocument('medicines', payLoad, templateId)
                .then((res) => {
                    if (res.status === 200) {
                        setPrescribedMedicines(res?.data?.medicines)
                        setIsSubmitting(false)
                        setPrescriptionMedicineToState(res?.data?.medicines)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    const searchInput = document.getElementById("my-drug-list")
                    if (searchInput) searchInput.value = ""
                    const payload = { doctorId: user?.user?._id, search: "" }
                    dispatch(getDoctorMedicineList(payload))
                    setIsSubmitting(false)
                });
        }
        else {
            let payLoad = {
                // doctorId: medicine?.doctorId,
                power: medicine?.power || "",
                type: medicine?.type || "",
                generic: medicine?.generic || "",
                manufacturer: medicine?.manufacturer || "",
                medicine: medicine?.medicineId || null,
                sig: medicine?.sig || "",
                name: medicine?.name || "",
                take: medicine?.take || "",
                route: medicine?.route || "",
                frequency: medicine?.frequency || "",
                repeat: medicine?.repeat || "",
                duration: medicine?.duration || "",
                doctorMedicineId: medicine?.doctorMedicineId || "",
                addToDoctorMedicine: medicine?.addToDoctorMedicine || false
            }

            await prescriptionAddSubDocument('medicines', payLoad, visitDetails?._id)
                .then((res) => {
                    if (res.status === 200) {
                        setPrescribedMedicines(res?.data?.medicines)
                        updateState(res?.data)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    const searchInput = document.getElementById("my-drug-list")
                    if (searchInput) searchInput.value = ""
                    const payload = { doctorId: user?.user?._id, search: "" }
                    dispatch(getDoctorMedicineList(payload))
                    setIsSubmitting(false)
                });
        }

    }

    const deleteMedicineFun = async (medicine) => {
        setIsSubmitting(true)
        if (templateId) {
            templateRemoveSubDocument('medicines', { _id: medicine?._id }, templateId)
                .then((res) => {
                    if (res?.status === 200) {
                        setPrescribedMedicines(res?.data?.medicines)
                        setIsSubmitting(false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => { setIsSubmitting(false) });
        }
        else {
            await prescriptionRemoveSubDocument('medicines', { _id: medicine?._id }, visitDetails?._id)
                .then((res) => {
                    if (res?.status === 200) {
                        setPrescribedMedicines(res?.data?.medicines)
                        updateState(res?.data)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => { setIsSubmitting(false) })
        }
    }

    const upDateMedicineFun = async (val, type, id) => {

        if (templateId) {
            let payload = {
                _id: id,
            }
            if (type === "sig") {
                payload["medicines.$.sig"] = val
            }
            if (type === "duration") {
                payload["medicines.$.duration"] = val
            }
            if (type == "name") {
                payload["medicines.$.name"] = val
            }
            if (val) {
                await templateUpdateSubDocument("medicines", payload, templateId).then((res) => {
                    if (res.status == 200) {
                        setPrescribedMedicines(res?.data?.medicines)
                    }
                })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally()
            }

        } else {
            let payload = {
                _id: id,
            }
            if (type === "sig") {
                payload[`medicines.$.sig`] = val
            }
            if (type === "duration") {
                payload[`medicines.$.duration`] = val
            }
            if (type == "name") {
                payload["medicines.$.name"] = val
            }
            if (val) {
                await prescriptionUpdateSubDocument("medicines", payload, visitDetails?._id).
                    then((res) => {
                        if (res.status == 200) {
                            setPrescribedMedicines(res?.data?.medicines)
                            updateState(res?.data)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally()
            }

        }
    }


    const setInfoFun = (key, value, type, id) => {
        let temp = { ...info };
        setInfo({ ...temp, [key]: value });
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { upDateMedicineFun(value, type, id) }, 700);
    };

    const checkOrganizationFun = (name = "Popular") => {
        if (user?.user?.details?.sponsor == "true")
            return (name.startsWith(user?.user?.organization?.manufacturerName))
        else return false
    }
    const medicineSearchFromMyList = (e) => {
        e.preventDefault()
        const payload = { doctorId: user?.user?._id, search: e.target.value?.trim() }
        dispatch(getDoctorMedicineList(payload))
    }

    const medicineSearchFromMyListWithTimeOut = (event) => {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => { medicineSearchFromMyList(event) }, 500);
    };


    const dragUp = (index, prescribedMedicines, id) => {
        let medicines = [...prescribedMedicines]
        if (medicines?.length > 0) {
            [medicines[index - 1], medicines[index]] = [medicines[index], medicines[index - 1]]
        }

        // const replacedElement = document.getElementById(`medicine-${id}`);
        setPrescribedMedicines(medicines)
        setSwapMedicinesToVisit(medicines)

    }
    const dragDown = (index, prescribedMedicines, id) => {
        let medicines = [...prescribedMedicines]
        if (medicines?.length > 0) {
            [medicines[index], medicines[index + 1]] = [medicines[index + 1], medicines[index]]
        }
        // const replacedElement = document.getElementById(`medicine-${id}`);
        setPrescribedMedicines(medicines)
        setSwapMedicinesToVisit(medicines)
    }


    const setSwapMedicinesToVisit = async (medicines) => {

        clearTimeout(timeoutIdRef.current);
        if(templateId){
            timeoutIdRef.current = setTimeout(async () => {
                await put(`/doctor/template/${templateId}`, { "medicines": medicines })
                    .then((res) => {
                        if (res.status === 200) {
                            updateState(res?.data)
                            setPrescribedMedicines(res?.data?.medicines)
                        }
                    })
                    .catch((e) => { })
                    .finally(() => { })
            }, 1500);
        }else{
            timeoutIdRef.current = setTimeout(async () => {
                await put(`/visit/${visitDetails?._id}`, { "medicines": medicines })
                    .then((res) => {
                        if (res.status === 200) {
                            updateState(res?.data)
                            setPrescribedMedicines(res?.data?.medicines)
                        }
                    })
                    .catch((e) => { })
                    .finally(() => { })
            }, 1500);
        }
    }

    const setPrescriptionMedicineToState = (medicines) => {
        medicines?.map((medicine) => {
            let key = `${"sig-" + medicine?._id}`
            let durationKey = `${"duration-" + medicine?._id}`
            let name = `${"name-" + medicine?._id}`
            setInfo((prev) => ({ ...prev, [key]: medicine?.sig, [durationKey]: medicine?.duration?.replace('/', ' '), [name]: medicine?.name }));

        })

    }


    useMemo(() => {
        if (visitDetails?.medicines) {
            setPrescribedMedicines(visitDetails?.medicines)
            setPrescriptionMedicineToState(visitDetails?.medicines)
        }
        if (templateDetails?.medicines ) {
            setPrescribedMedicines(templateDetails?.medicines)
            setPrescriptionMedicineToState(templateDetails?.medicines)
        }
    }, [visitDetails, templateDetails])




    return (
        <div className='prescription-medicine-section'>
            <div className='prescribed-medicine'>
                <div className='' style={{ height: "57vh", overflowY: "scroll" }}>
                    <table className="table table-sm table-hover ">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Sig</th>
                                <th scope="col">Duration</th>
                                <th className='text-center' scope="col">Action</th>
                                {/* <th scope="col">Note</th> */}
                            </tr>
                        </thead>
                        <tbody style={{ position: "relative" }}>
                            {prescribedMedicines?.length > 0 ? prescribedMedicines?.map((medicine, index) => {
                                return (
                                    <tr key={index} className='single-medicine' id={`medicine-${medicine?._id}`}>
                                        <td className='align-middle ' style={{ cursor: "pointer", position: "relative" }}><span>
                                            {index != 0 && <FontAwesomeIcon className='arrow' style={{ position: "absolute", top: "0%" }} onClick={() => {
                                                dragUp(index, prescribedMedicines, medicine?._id)
                                            }} icon={faArrowAltCircleUp}></FontAwesomeIcon>}
                                            {index != (prescribedMedicines?.length - 1) && <FontAwesomeIcon className='arrow' style={{ position: "absolute", bottom: "0%" }} onClick={() => {
                                                dragDown(index, prescribedMedicines, medicine?._id)
                                            }} icon={faArrowAltCircleDown}></FontAwesomeIcon>}
                                            {index + 1}
                                        </span></td>
                                        <td className='align-middle'><input
                                            onChange={(e) => {
                                                setInfoFun(`${"name-" + medicine?._id}`, e.target.value, "name", medicine?._id)
                                            }}
                                            value={info[`${"name-" + medicine?._id}`]} type='text'></input></td>
                                        <td className='align-middle' >  <textarea
                                            onDoubleClick={() => {
                                                setSelectedMedicine(medicine)
                                                setShow(true)
                                                setIsDoubleClick(true)
                                            }}
                                            // onClick={() => { setSig(medicine?.sig) }}
                                            className="form-control  w-100 "
                                            autoComplete="off"
                                            type="text"
                                            rows={2}
                                            placeholder="Sig"
                                            value={info[`${"sig-" + medicine?._id}`]}
                                            onChange={(e) => {
                                                setInfoFun(`${"sig-" + medicine?._id}`, e.target.value, "sig", medicine?._id)
                                            }}
                                        /></td>
                                        <td className='align-middle'><input type='text' onDoubleClick={() => {
                                            setSelectedMedicine(medicine)
                                            setShow(true)
                                            setIsDoubleClick(true)
                                        }} onChange={(e) => {
                                            setInfoFun(`${"duration-" + medicine?._id}`, e.target.value, "duration", medicine?._id)
                                        }} value={info[`${"duration-" + medicine?._id}`]?.replace('/', ' ')}></input></td>

                                        <td className='text-center'><span className='icon'> <FontAwesomeIcon onClick={() => {
                                            deleteMedicineFun(medicine)
                                        }} icon={faTrash}></FontAwesomeIcon></span></td>
                                    </tr>

                                )
                            }) : <small></small>}
                            {isSubmitting && <UniversalLoaderForComponent></UniversalLoaderForComponent>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='medicine-list'>
                <div className='d-flex mb-1'>
                    <input autoComplete="off" style={{ border: ".8px solid black" }} value={searchString} placeholder={`Search global medicine by ${searchType} `} type='text' onChange={(event) => {
                        medicineSearchWithTimeOut(event.target.value, event)
                    }} />
                    <select
                        onChange={(e) => {
                            medicineSearchWithTimeOut('', e)
                            setSearchType(e.target.value);
                        }}
                    >
                        <option value={"name"}> Name</option>
                        <option value={"generic"}>Generic </option>
                    </select>{" "}
                </div>
                <div className='scroll-section'>
                    {medicineList && !docMedicineShow ? medicineList?.map((medicine, index) => {
                        return (
                            <div style={{ cursor: "pointer" }} className={`individual-medicine py-1 border ${checkOrganizationFun(medicine?.manufacturer) == true ? "same_org" : ""}`} key={'drug-search-index-' + index} onClick={() => checkAndAddMedicine(medicine)}>
                                <small className='px-1 d-block p-0 m-0' style={{ height: "10px", fontSize: "10px", fontStyle: "italic" }}>{medicine?.type?.toLowerCase()}</small>
                                <small className='px-1 ' style={{ height: "10px", color: "black", fontStyle: "italic" }}>{medicine?.name}</small>
                                <small className='px-1' style={{ height: "10px", fontSize: "10px", fontStyle: "italic" }}>{medicine?.power?.slice(0, 15)?.toLowerCase()}</small>
                            </div>
                        )
                    }) : ""}

                    <div className='my-drug-list'>
                        {docMedicineShow && <small className='pl-1 d-block my-drug-title' style={{ color: "black" }}>My drug list</small>}
                        {docMedicineShow && <input autoComplete="off"  style={{ border: ".8px solid black" }} id="my-drug-list" placeholder={`Search from my drugs`} type='text' onChange={(event) => {
                            medicineSearchFromMyListWithTimeOut(event)
                        }} />}
                        {docMedicineShow && doctorMedicineList ? doctorMedicineList?.map((medicine, index) => {
                            return (
                                <div className='d-flex justify-content-between mr-2 individual-medicine' key={'my-drug-list' + index} >
                                    <div className='py-1' style={{ width: "85%" }} onClick={() => checkAndAddMedicine(medicine)}>
                                        <small className='px-1 d-block' style={{ height: "10px", fontSize: "10px", fontStyle: "italic" }}>{medicine?.type?.toLowerCase()}</small>
                                        <small className=' px-1' style={{ height: "10px", color: "black", fontStyle: "italic" }}>{medicine?.name}</small>
                                        <small className='px-1' style={{ height: "10px", fontSize: "10px", fontStyle: "italic" }}>{medicine?.power?.slice(0, 10)?.toLowerCase()}</small>

                                    </div>
                                    <FontAwesomeIcon size='xs' className='mt-3' onClick={() => {
                                        setIsDocMedicineUp(true)
                                        setShow(true)
                                        setSelectedMedicine(medicine)
                                    }} icon={faEdit}></FontAwesomeIcon>
                                </div>
                            )
                        }) : ""}
                    </div>
                    {/* {(isNoMedicine && !isLoading && searchString.length >= 0 && !docMedicineShow) ? <small onClick={() => {
                        addNewMedicine(searchString)
                    }} className='d-block px-1'>Create(<span className='text-dark'>{searchString}</span>)</small> : ""} */}
                    {(!isLoading && searchString.length >= 0 && !docMedicineShow) ? <small onClick={() => {
                        addNewMedicine(searchString)
                    }} className='d-block px-1 text-success py-2'>Create(<span className='text-dark'>{searchString}</span>)</small> : ""}
                    {isMedicineListLoading && <small className='px-1'>Loading...</small>}
                    {isDoctorMedicineListLoading && docMedicineShow && <small className='px-1'>Loading...</small>}
                </div>
            </div>
            <CustomModal
                customClass="default"
                size="md"
                show={show}
                setShow={setShow}
                isHeaderHide={true} >
                <MedicineSigForm templateId={templateId} setPrescribedMedicines={setPrescribedMedicines} visitId={visitDetails?._id} updateState={updateState} isDocMedicineUp={isDocMedicineUp} setIsDocMedicineUp={setIsDocMedicineUp} isDoubleClick={isDoubleClick} setIsDoubleClick={setIsDoubleClick} setDocMedicineShow={setDocMedicineShow} setSearchString={setSearchString} searchString={searchString} doctorId={visitDetails?.doctor || user?.user?._id} medicineAddToPrescription={medicineAddToPrescription} selectedMedicine={selectedMedicine} setShow={setShow}></MedicineSigForm>
            </CustomModal>
        </div>
    )
}

export default Medicine
