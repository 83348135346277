import React, { useEffect, useMemo, useRef, useState } from 'react'
import "./lab-test.scss"
import { useDispatch, useSelector } from 'react-redux';
import CreatableSearchSelect from '../../../common/updated-input/createble-search-select/creatable-search-select';
import { getAllLabTests } from '../../../../store/slices/prescriptionSlice';
import { patientLabTestAdd } from '../../../../service/api/patient-api';
import { addLabTest } from '../../../../service/api/medical-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { prescriptionAddSubDocument, prescriptionRemoveSubDocument, prescriptionUpdateSubDocument } from '../../../../service/api/prescription';
import {
    templateAddSubDocument,
    templateRemoveSubDocument,
    templateUpdateSubDocument
} from '../../../../service/api/template';
import UniversalLoaderForComponent from '../../../loader/universalLoaderForComponent';
import { put } from '../../../../utils/fetchAPI';
import CreatableSearchSelectAntD from '../../../common/updated-input/createble-search-select/creatable-search-select-antd';

const LabTest = ({ doctorId = "", templateId = "", visitDetails, updateState }) => {
    let { labTestList, isLabTestListLoading } = useSelector((state) => state.prescription);
    let { templateDetails } = useSelector((state) => state.template);
    let [prescribedLabTest, setPrescribedLabTest] = useState([])
    const [options, setOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    let timeoutIdRef = useRef()

    useEffect(() => {
        if (doctorId || visitDetails?.doctor) {
            const payLoad = { doctorId: visitDetails?.doctor || doctorId, search: '' }
            dispatch(getAllLabTests(payLoad))
        }
    }, [visitDetails?.doctor, doctorId])

    const setOptionFun = (event) => {
        event.preventDefault()
        const payLoad = { doctorId: visitDetails?.doctor || doctorId, search: event.target.value }
        dispatch(getAllLabTests(payLoad))
    }

    const handleSubmit = (value) => {
        labTestAddFun(value?._id, value?.value)
    }

    const handleCreate = async (value) => {
        await addLabTest({ doctor: visitDetails?.doctor || doctorId, name: value })
            .then((res) => {
                if (res.status === 200) {
                    labTestAddFun(res?.data?._id, res?.data?.name)
                    const payLoad = { doctorId: visitDetails?.doctor || doctorId, search: '' }
                    dispatch(getAllLabTests(payLoad))
                }
            })
    }

    const labTestAddFun = async (labTestId, labTestName) => {
        setIsLoading(true)
        if (!templateId) {
            const payLoad = {
                labTest: labTestId,
                name: labTestName
            }

            await prescriptionAddSubDocument('labTests', payLoad, visitDetails?._id)
                .then((res) => {
                    if (res.status === 200) {
                        setPrescribedLabTest(res?.data?.labTests)
                        updateState(res?.data)
                        // prescriptionRefresh()
                    }
                })
                .finally(() => { setIsLoading(false) })

        }
        else if (templateId) {
            const payLoad = {
                labTest: labTestId,
                name: labTestName
            }

            if (templateId) {
                await templateAddSubDocument('labTests', payLoad, templateId)
                    .then((res) => {
                        if (res.status === 200) {
                            setPrescribedLabTest(res?.data?.labTests)
                        }
                    })
                    .finally(() => { setIsLoading(false) })
            }
        }

    }


    const deleteLabTestFun = async (labTest) => {
        setIsLoading(true)
        if (!templateId) {
            await prescriptionRemoveSubDocument('labTests', { _id: labTest?._id }, visitDetails?._id)
                .then((res) => {
                    if (res?.status === 200) {
                        setPrescribedLabTest(res?.data?.labTests)
                        updateState(res?.data)
                    }
                }).finally(() => { setIsLoading(false) })
        }
        else if (templateId) {
            await templateRemoveSubDocument('labTests', { _id: labTest?._id }, templateId)
                .then((res) => {
                    if (res?.status === 200) {
                        setPrescribedLabTest(res?.data?.labTests)
                    }
                }).finally(() => { setIsLoading(false) })
        }
    }

    useMemo(() => {
        if (visitDetails?.labTests) {
            setPrescribedLabTest(visitDetails?.labTests)
        } else if (templateId) {
            setPrescribedLabTest(templateDetails?.labTests)
        }
    }, [visitDetails?.labTests, templateDetails])


    const labTestEdit = (value, labTest, index) => {
        let oldLabTests = [...prescribedLabTest]
        let current = {}
        if (oldLabTests[index])
            current = oldLabTests[index]
        current = { ...current, name: value }
        oldLabTests[index] = current
        setPrescribedLabTest([...oldLabTests])
        clearTimeout(timeoutIdRef.current);

        timeoutIdRef.current = setTimeout(async () => {
            let payLoad = {}
            payLoad._id = labTest?._id
            payLoad[`labTests.$.name`] = value

            if (templateId) {
                await templateUpdateSubDocument("labTests", payLoad, templateId).
                    then((res) => {
                        if (res.status == 200) {
                            setPrescribedLabTest(res?.data?.labTests)
                            updateDoctorLabTest(labTest?.labTest, value, visitDetails?.doctor || doctorId)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally()
            }
            else {
                await prescriptionUpdateSubDocument("labTests", payLoad, visitDetails?._id).
                    then((res) => {
                        if (res.status == 200) {
                            setPrescribedLabTest(res?.data?.labTests)
                            updateState(res?.data)
                            updateDoctorLabTest(labTest?.labTest, value, visitDetails?.doctor || doctorId)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally()
            }

        }, 1000);
    }


    const updateDoctorLabTest = async (labTestId, name, doctorId) => {
        await put(`doctor/lab-test/update`, { labTestId: labTestId, name: name, doctorId: doctorId }).
            then((res) => {
                if (res.status == 200) {
                    const payLoad = { doctorId: visitDetails?.doctor || doctorId, search: '' }
                    dispatch(getAllLabTests(payLoad))
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally()
    }

    useMemo(() => {
        let allLabtests = []
        if (labTestList?.length > 0) {
            labTestList?.map((data) => {
                allLabtests.push({ value: JSON.stringify({ _id: data?._id, value: data?.name }), label: data?.name })
            })
            setOptions(allLabtests)
        }
    }, [labTestList])

    return (
        <div>
            {/* <CreatableSearchSelect customClass='custom-color-border-1' handleSubmit={handleSubmit} handleCreate={handleCreate} isSearching={isLabTestListLoading} setOptionFun={setOptionFun} options={labTestList} placeHolder='Search lab test'></CreatableSearchSelect> */}
            <div className='mx-2'>
                <CreatableSearchSelectAntD customClass='custom-color-border-1' placeHolderColor="#13AC81" handleSubmit={handleSubmit} handleCreate={handleCreate} isSearching={isLabTestListLoading} setOptionFun={setOptionFun} options={options} placeHolder='Search labtest'></CreatableSearchSelectAntD>
            </div>

            <div className='mt-2 added-lab-test-section'>
                {prescribedLabTest?.length > 0 ? prescribedLabTest?.map((labTest, index) => {
                    return (
                        <div key={index} className='d-flex justify-content-between individual-lab-test'>
                            <div className='mx-2' style={{ width: "70%" }}>
                                <input type='text' onChange={(e) => { labTestEdit(e.target.value, labTest, index) }} value={labTest?.name}></input>
                            </div>
                            <span onClick={() => {
                                deleteLabTestFun(labTest)
                            }} className='icon my-auto'> <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></span>
                        </div>
                    )
                }) : ""}

                {isLoading && <UniversalLoaderForComponent></UniversalLoaderForComponent>}
            </div>

        </div>

    )
}

export default LabTest
