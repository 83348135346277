export const getName = (userName) => {
  let name = userName?.split(" ");
  if (name?.length < 2) return name[0][0].toUpperCase();
  else if (name?.length >= 2)
    return name[0][0].toUpperCase() + name[1][0].toUpperCase();
};

export const dateFormat = (value) => {
  let d = value?.split("-") || []
  if (d[1] && d[2] && d[0])
    return ((d[2] + "-" + d[1] + "-" + d[0]))
  else return ""
}

export const checkDobUnit=(date)=>{
  const inputDate = new Date(date);
  const currentDate = new Date();

  const diffInTime = currentDate - inputDate;
  const diffInDays = diffInTime / (1000 * 60 * 60 * 24);
  const diffInMonths = diffInDays / 30; // Approximate month calculation

  if (diffInMonths >= 12) {
      return "year(s)";
  } else if (diffInMonths <= 11) {
      return "month(s)";
  } else {
      return "day(s)";
  }
}

export const convertDobToAge = (date, ageUnit) => {
  let currentDate = new Date();
  let dob = new Date(date)

  if (ageUnit == 'year(s)') {
    let year = currentDate.getFullYear() -
      dob.getFullYear()
    if (year == 0) {
      let mnth = currentDate.getMonth() -
        dob.getMonth() + (12 * (currentDate.getFullYear() - dob.getFullYear()))
      if (mnth == 0) {
        const msInDay = 24 * 60 * 60 * 1000;
        let day = Math.round(
          Math.abs(currentDate - dob) / msInDay
        )
        return day + " " + "day(s)"
      }
      return mnth + " " + "month(s)"
    }
    return year + " " + ageUnit
  }


  else if (ageUnit == 'month(s)') {
    let mnth = currentDate.getMonth() -
      dob.getMonth() + (12 * (currentDate.getFullYear() - dob.getFullYear()))
    if (mnth == 0) {
      const msInDay = 24 * 60 * 60 * 1000;
      let day = Math.round(
        Math.abs(currentDate - dob) / msInDay)
      return day + " " + "day(s)"
    }
    else if (mnth > 12) {
      let year = currentDate.getFullYear() -
        dob.getFullYear()
      return year + " " + "year(s)"
    }
    return mnth + " " + ageUnit
  }


  else if (ageUnit == 'day(s)') {
    const msInDay = 24 * 60 * 60 * 1000;
    let day = Math.round(
      Math.abs(currentDate - dob) / msInDay
    )
    if (day > 30) {
      let mnth = currentDate.getMonth() -
        dob.getMonth() + (12 * (currentDate.getFullYear() - dob.getFullYear()))
      return mnth + " " + "month(s)"
    }
    return day + " " + ageUnit
  }
}


export const convertAgeToDob = (age, ageUnit) => {
  let date = new Date();

  let currentDate = ''
  if (ageUnit == "day(s)") {
    date.setDate(date.getDate() - age);
    currentDate = JSON.stringify(date)
  }

  else if (ageUnit == "month(s)") {
    date.setMonth(date.getMonth() - age);
    currentDate = JSON.stringify(date)
  }
  else if (ageUnit == "year(s)") {
    date.setFullYear(date.getFullYear() - age);
    currentDate = JSON.stringify(date)
  }
  return currentDate?.slice(1, 11)
}
export const formatDate = (dateString) => {
  // Create a Date object from the input date string
  const date = new Date(dateString);

  // Define the months array
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Extract the day, month, and year
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  // Format the date in the desired format
  return `${day} ${month}, ${year}`;
}


export function calculateDaysBetween(date) {
  const inputDate = new Date(date);
  const currentDate = new Date();
  const differenceInTime = inputDate - currentDate;
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
  return differenceInDays;
}
