import React, { useEffect } from 'react'
import "./main-prescription.scss"
import Medicine from '../../prescription/main-prescription/medicine/medicine'
import { useDispatch, useSelector } from 'react-redux'
import { getTemplateDetails } from '../../../store/slices/templateSlice'
import Complaints from './complaints/complaints'
import LabTest from '../../prescription/main-prescription/labtest/labtest'
import Advice from '../../prescription/main-prescription/advice/advice'
import DiagnosisTemplate from './diagnosis/diagnosis'

const TemplatePrescription = ({ selectedTemplate, doctorId }) => {
    const dispatch = useDispatch();
    let { templateDetails } = useSelector((state) => state.template);

    useEffect(() => {
        const payload = { templateId: selectedTemplate?._id, doctorId: doctorId }
        dispatch(getTemplateDetails(payload));
    }, [selectedTemplate]);

    
    return (
        <div className='prescription-full-body' >
            <div className='top-section'>
                <div className='compliant-section' >
                    <p className='border py-1 title' > Chief Complaints</p>
                    <Complaints doctorId={doctorId} templateId={selectedTemplate?._id}></Complaints>
                    <DiagnosisTemplate doctorId={doctorId} templateId={selectedTemplate?._id}></DiagnosisTemplate>
                </div>
                <div className='medicine-section position-relative'>
                    <Medicine templateDetails={templateDetails} doctorId={doctorId} templateId={selectedTemplate?._id}></Medicine>
                </div>
            </div>
            <div className='labTest-advice-section'>
                <div className='lab-test position-relative'>
                    <p className='title'>Lab test</p>
                    <LabTest doctorId={doctorId} templateId={selectedTemplate?._id}></LabTest>
                </div>
                <div>
                    <p className='title position-relative'>Advice</p>
                    <Advice doctorId={doctorId} templateId={selectedTemplate?._id}></Advice>
                </div>
            </div>
        </div>
    )
}

export default TemplatePrescription
